import {
  Box,
  FormControl, HStack,
  Input,
  InputGroup, InputLeftElement,
  InputRightElement,
  Stack,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import Divider from '../Divider'
import {FC, useMemo, useRef, useState} from 'react'
import useWindowSize from '../../hooks/useWindowSize'
import TokenIcon from "../TokenIcon";
import {shortenAddress} from "../../utils";
import {BiChevronDownCircle} from "react-icons/all";

type OptionInput = {
  title: string
  defaultValue: string
  datalist: item[]
  readonly: boolean
  onCheck: (value: string) => boolean
  onChange: (value: string) => void
  placeholder: string
  symbol: string
}

type item = {
  name: string
  symbol: string
  address: string
}

const SelectReserve: FC<OptionInput> = ({...props}) => {
  const [showOption, setShowOption] = useState(false)
  const [value, setValue] = useState(props.defaultValue)
  const inputRef = useRef(null)
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const {width} = useWindowSize()
  const [isInput, setIsInput] = useState(false)

  const filterResult = useMemo(() => props.datalist
    .filter((item) => item.symbol.toLowerCase().includes(value.toLowerCase())
      || item.name.toLowerCase().includes(value.toLowerCase())
      || item.address.toLowerCase().includes(value.toLowerCase())
    ), [props.datalist, value])

  return (
    <Box pb={showOption ? (isLargerThan1024 ? '40px' : '44px') : '0'} w={isLargerThan1024 ? '600px' : 'full'}>
      <Text
        fontWeight={'600'}
        mb={isLargerThan1024 ? '16px' : '10px'}
        fontSize={isLargerThan1024 ? 'md' : 'xs'}
        ml={'16px'}
        color={'secondary.500'}
      >
        {props.title}:
      </Text>
      <FormControl
        bg={'white'}
        width={isLargerThan1024 ? 600 : width - 66}
        borderRadius={showOption ? '10px' : '0'}
        border={showOption ? '1px' : '0'}
        borderColor={'primary.500'}
        pos={showOption ? 'absolute' : 'static'}
        zIndex={showOption ? 10 : 0}
      >
        <InputGroup>
          {props.symbol !== '' && !isInput && (
            <InputLeftElement
              pl={'12px'}
              h={'full'}
              children={<TokenIcon symbol={props.symbol}/>}
            />
          )}
          <Input
            id={'amount'}
            ref={inputRef}
            minH={isLargerThan1024 ? '40px' : '44px'}
            variant={showOption ? 'unstyled' : 'filled'}
            errorBorderColor={'primary.500'}
            placeholder={props?.placeholder || ''}
            isReadOnly={props.readonly}
            pr={value !== '' ? '85px' : '0'}
            fontSize={value === '' ? (isLargerThan1024 ? '15px' : 'xs') : '17px'}
            isInvalid={props.onCheck(value)}
            onChange={(event) => {
              setIsInput(true)
              setValue(event.target.value)
              props.onChange(event.target.value)
            }}
            value={value}
            onFocus={(e) => {
              e.target.setSelectionRange(0, value.length)
              setShowOption(true)
            }}
            onBlur={() => {
              setIsInput(false)
              setTimeout(() => setShowOption(false), 200)
            }}
          />
          <InputRightElement
            onClick={() => {
              if (!showOption) {
                // @ts-ignore
                inputRef.current.focus()
              }
            }}
            cursor={'pointer'}
            w={'100px'}
            h={'full'}
            justifyContent={"end"}
            children={
              <HStack pr={'12px'}>
                {value !== '' && !isInput && (
                  <Text fontWeight={'semibold'} color={props.onCheck(value) ? 'red' : 'primary.500'}
                        textAlign={'right'}>
                    {props.symbol}
                  </Text>
                )}
                {!props.readonly && (
                  <BiChevronDownCircle size={isLargerThan1024 ? '22px' : '16px'} color={'#878787'}/>
                )}
              </HStack>
            }
          />
        </InputGroup>

        {filterResult.length > 0 && !props.readonly && (
          <Stack hidden={!showOption} pb={1} top={'72px'} spacing={0}>
            <Divider/>
            <Stack h={'400px'} overflow={"scroll"}>
              {filterResult
                .map((item, index) => (
                  <HStack
                    key={index}
                    cursor={"pointer"}
                    py={'11px'}
                    px={'17px'}
                    onClick={() => {
                      setValue(item.address)
                      setShowOption(false)
                      props.onChange(item.address)
                    }}
                    _hover={{bg: 'secondary.400'}}>
                    <TokenIcon symbol={item.symbol} size={'44px'}/>
                    <Stack spacing={'0'}>
                      <Text fontWeight={'medium'}>{item.name} ({item.symbol})</Text>
                      <Text fontSize={'sm'}
                            color={'secondary.500'}>{isLargerThan1024 ? item.address : shortenAddress(item.address)}</Text>
                    </Stack>
                  </HStack>
                ))}
            </Stack>
          </Stack>
        )}
      </FormControl>
    </Box>
  )
}

export default SelectReserve
