import useAbcProjects, {ABCProject} from "./useAbcProjects";
import {useCallback} from "react";
import {ABC_PLATFORM_ADDRESS, NEST_BATCH_PLATFORM2_ADDRESS} from "../constants/addresses";
import {parseToBigNumber} from "../utils/bignumberUtil";
import {useToken} from "./Tokens";
import BigNumber from "bignumber.js";
import {useABCPlatform, useNESTBatchPlatform2} from "./useContract";
import useActiveWeb3React from "./useActiveWeb3React";

const useAbcProject = (project: ABCProject | undefined) => {
  const {chainId, account} = useActiveWeb3React()
  const {setProjects} = useAbcProjects()
  const {totalSupply: stableTotalSupply, balanceOf: stableBalanceOf} = useToken(project?.stablecoin)
  const {balanceOf: targetBalanceOf} = useToken(project?.target)
  const nestBatchPlatform2 = useNESTBatchPlatform2(NEST_BATCH_PLATFORM2_ADDRESS[chainId ?? 1])
  const abcPlatform = useABCPlatform(ABC_PLATFORM_ADDRESS[chainId ?? 1])

  const fetchReserve = useCallback(() => {
    if (project?.stablecoin && account) {
      targetBalanceOf(project?.stablecoin)
        .then((res) => {
          setProjects((pre) =>
            pre.map((e) =>
              e.index === project?.index
                ? {
                  ...e,
                  reserve: res.toFixed(),
                }
                : e
            )
          )
        })
        .catch(() => console.log('fetch reserve error'))
    }
  }, [project?.stablecoin, account])

  const fetchLocked = useCallback(() => {
    if (account) {
      stableBalanceOf(ABC_PLATFORM_ADDRESS[chainId ?? 1])
        .then((res) => {
          setProjects((pre) =>
            pre.map((e) =>
              e.index === project?.index
                ? {
                  ...e,
                  locked: res.toFixed(),
                }
                : e
            )
          )
        })
        .catch(() => console.log('fetch locked error'))
    }
  }, [chainId, stableBalanceOf, account])

  const fetchTotalSupply = useCallback(() => {
    if (account) {
      stableTotalSupply.then((res) => {
        setProjects((pre) =>
          pre.map((e) =>
            e.index === project?.index
              ? {
                ...e,
                totalSupply: res.toFixed(),
              }
              : e
          )
        )
      })
        .catch((e) => console.log('fetch totalSupply error'))
    }
  }, [stableTotalSupply, account])

  const fetchCirculation = useCallback(() => {
    if (account) {
      stableTotalSupply.then((res) => {
        setProjects((pre) =>
          pre.map((e) =>
            e.index === project?.index
              ? {
                ...e,
                circulation: parseToBigNumber(res).minus(parseToBigNumber(project?.locked)).toFixed(),
              }
              : e
          )
        )
      })
    }
  }, [stableTotalSupply, project?.locked, account])

  const fetchMyHoldings = useCallback(() => {
    if (account) {
      stableBalanceOf(account)
        .then((res) => {
          setProjects((pre) =>
            pre.map((e) =>
              e.index === project?.index
                ? {
                  ...e,
                  myHoldings: res.toFixed(),
                }
                : e
            )
          )
        })
        .catch(() => console.log('fetch myHoldings error'))
    }
  }, [account, stableBalanceOf])

  const fetchMyAssets = useCallback(() => {
    if (account) {
      setProjects((pre) =>
        pre.map((e) =>
          e.index === project?.index
            ? {
              ...e,
              myAssets: parseToBigNumber(project?.myPandIBalance).plus(project?.myHoldings).toFixed(),
            }
            : e
        )
      )
    }
  }, [project?.myHoldings, project?.myPandIBalance])

  const fetchMyStake = useCallback(() => {
    if (abcPlatform && account && project?.index) {
      abcPlatform
        .balanceOf(project?.index, account)
        .then((res) => {
          setProjects((pre) =>
            pre.map((e) =>
              e.index === project?.index
                ? {
                  ...e,
                  myStake: parseToBigNumber(res).shiftedBy(-18).toFixed(),
                }
                : e
            )
          )
        })
        .catch(() => console.log('fetch my stake error'))
    }
  }, [abcPlatform, account])

  const fetchMyEarned = useCallback(() => {
    if (abcPlatform && account && project?.index) {
      abcPlatform
        .earned(project?.index, account)
        .then((res) => {
          setProjects((pre) =>
            pre.map((e) =>
              e.index === project?.index
                ? {
                  ...e,
                  myEarned: parseToBigNumber(res).shiftedBy(-18).toFixed(),
                }
                : e
            )
          )
        })
        .catch(() => console.log('fetch my earned error'))
    }
  }, [abcPlatform, account])

  const fetchMyPandI = useCallback(() => {
    if (account) {
      setProjects((pre) =>
        pre.map((e) =>
          e.index === project?.index
            ? {
              ...e,
              myPandIBalance: parseToBigNumber(project?.myEarned).plus(project?.myStake).toFixed(),
            }
            : e
        )
      )
    }
  }, [project?.myEarned, project?.myStake, account])

  const fetchPrice = useCallback(() => {
    if (nestBatchPlatform2 && project && project.unit !== 0) {
      nestBatchPlatform2['triggeredPriceInfo(uint256,uint256)'](project?.channelId, project?.pairIndex).then(
        ({price}) => {
          setProjects((pre) =>
            pre.map((e) =>
              e.index === project?.index
                ? {
                  ...e,
                  price: parseToBigNumber(price).eq(0) ? '0' : new BigNumber(project.unit).div(parseToBigNumber(price).shiftedBy(-18))
                    .toFixed()
                }
                : e
            )
          )
        }
      )
    }
  }, [nestBatchPlatform2])

  const fetchAll = useCallback(async () => {
    await fetchReserve()
    await fetchLocked()
    await fetchTotalSupply()
    await fetchCirculation()
    await fetchMyHoldings()
    await fetchMyStake()
    await fetchMyEarned()
    await fetchMyPandI()
    await fetchMyAssets()
    await fetchPrice()
  }, [fetchCirculation, fetchLocked, fetchMyAssets, fetchMyEarned, fetchMyHoldings, fetchMyPandI, fetchMyStake, fetchPrice, fetchReserve, fetchTotalSupply])

  return {
    fetchReserve,
    fetchLocked,
    fetchTotalSupply,
    fetchCirculation,
    fetchMyHoldings,
    fetchMyAssets,
    fetchMyStake,
    fetchMyEarned,
    fetchMyPandI,
    fetchPrice,
    fetchAll
  }
}

export default useAbcProject