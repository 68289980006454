import {Button, HStack, Input, Link, Stack, Text, useMediaQuery} from '@chakra-ui/react'
import {ArrowBackIcon} from '@chakra-ui/icons'
import InputWithSelect from '../../components/InputWithSelect'
import {useEffect, useMemo, useState} from 'react'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {useABCPlatform} from '../../hooks/useContract'
import {
  ABC_PLATFORM_ADDRESS,
  HBTC_ADDRESS,
  NEST_ADDRESS,
  PBTC_ADDRESS,
  PETH_ADDRESS,
  PUSD_ADDRESS
} from '../../constants/addresses'
import {parseToBigNumber} from '../../utils/bignumberUtil'
import {ERROR, IDLE, IDLE_DELAY, PROCESSING, SUCCESS, ZERO_ADDRESS} from '../../constants/misc'
import useNestPlatform, {ChannelInfo} from '../../hooks/useNestPlatform'
import SelectOracle from '../../components/SelectOracle'
import {isAddress} from '../../utils'
import {useToken} from '../../hooks/Tokens'
import SelectReserve from "../../components/SelectReserve";
import {SupportedChainId} from "../../constants/chains";
import ethData from "../../assets/json/eth.json"
import polygonData from "../../assets/json/polygon.json"
import kccData from "../../assets/json/kcc.json"
import bnbData from "../../assets/json/bnb.json"
import useActiveWeb3React from "../../hooks/useActiveWeb3React";

const Create = () => {
  const [reserveAddress, setReserveAddress] = useState('')
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [apy, setApy] = useState('0')
  const [oracle, setOracle] = useState<ChannelInfo>()
  const navigate = useNavigate()
  const [params] = useSearchParams()
  const isConfirm = params.getAll('a').includes('confirm')
  const {chainId} = useActiveWeb3React()
  const abcPlatform = useABCPlatform(ABC_PLATFORM_ADDRESS[chainId ?? 1])
  const [status, setStatus] = useState(IDLE)
  const [symbol, setSymbol] = useState('')
  const {channelList} = useNestPlatform()
  const {symbol: reserveSymbol} = useToken(reserveAddress)

  const create = async () => {
    if (abcPlatform && oracle) {
      setStatus(PROCESSING)
      try {
        const q = await abcPlatform.open(
          oracle.channelId,
          oracle.pairIndex,
          parseToBigNumber(apy).shiftedBy(2).toFixed(0)
        )
        const res = await q.wait()
        switch (res.status) {
          case 0:
            setStatus(ERROR)
            setTimeout(() => {
              setStatus(IDLE)
            }, IDLE_DELAY)
            break
          case 1:
            setStatus(SUCCESS)

            setTimeout(() => {
              setStatus(IDLE)
              if (res.events && res.events?.length > 0 && res.events[0]?.args && res.events[0]?.args.length > 0) {
                // const projectId = parseToBigNumber(res.events[0]?.args[0]).toNumber()
                navigate(`/p`)
              }
            }, IDLE_DELAY)
            break
          default:
            setStatus(ERROR)
            setTimeout(() => {
              setStatus(IDLE)
            }, IDLE_DELAY)
            break
        }
      } catch (e) {
        setStatus(ERROR)
        setTimeout(() => {
          setStatus(IDLE)
        }, IDLE_DELAY)
      }
    }
  }

  useEffect(() => {
    if (reserveAddress === '') {
      setSymbol('')
      return
    }
    if (reserveAddress === ZERO_ADDRESS) {
      setSymbol('ETH')
      return
    }
    reserveSymbol
      .then((res) => {
        if (res) {
          setSymbol(res)
        } else {
          setSymbol('Error')
        }
      })
      .catch((e) => setSymbol('Error'))
  }, [reserveSymbol, reserveAddress])

  useEffect(() => {
    const res = channelList
      .filter((e) => e.token === reserveAddress)
      .map((item, index) => ({
        title: item.title,
        channelId: item.channelId,
        pairIndex: item.pairIndex,
        priceToken: item.priceToken,
        token: item.token,
      }))
    if (res.length === 1) {
      setOracle(res[0])
    }
  }, [reserveAddress, channelList])

  const datalist = useMemo(()=>{
    if (chainId === SupportedChainId.MAINNET) {
      return ethData
    } else if (chainId === SupportedChainId.POLYGON) {
      return polygonData
    } else if (chainId === SupportedChainId.KCC) {
      return kccData
    } else if (chainId === SupportedChainId.BSC) {
      return bnbData
    } else {
      return [
        {name: 'Ethereum', symbol: 'ETH', address: PETH_ADDRESS[chainId ?? 1]},
        {name: 'PBTC', symbol: 'PBTC', address: PBTC_ADDRESS[chainId ?? 1]},
        {name: 'PUSD', symbol: 'PUSD', address: PUSD_ADDRESS[chainId ?? 1]},
        {name: 'NEST', symbol: 'NEST', address: NEST_ADDRESS[chainId ?? 1]},
      ]
    }
  }, [chainId])

  return (
    <Stack h={'full'} px={'22px'} pt={isLargerThan1024 ? '22px' : 0} pb={'22px'} spacing={'22px'}>
      {!isLargerThan1024 && (
        <HStack>
          <Button
            variant={'ghost'}
            onClick={() => navigate(isConfirm ? '/p/create' : '/p')}
            leftIcon={<ArrowBackIcon color={'primary.500'} fontSize={'xl'}/>}
            fontWeight={'semibold'}
            color={'secondary.500'}
          >
            {isConfirm ? 'Confirm new project' : 'Create project'}
          </Button>
        </HStack>
      )}
      <Stack
        bg={'white'}
        px={'11px'}
        py={'22px'}
        borderRadius={'12px'}
        spacing={0}
        minH={isLargerThan1024 ? '720px' : ''}
        border={'1px solid'}
        borderColor={'secondary.300'}
      >
        {isLargerThan1024 && (
          <HStack>
            <Button
              variant={'ghost'}
              onClick={() => navigate(isConfirm ? '/p/create' : '/p')}
              leftIcon={<ArrowBackIcon color={'primary.500'} fontSize={'xl'}/>}
              fontWeight={'semibold'}
              color={'secondary.500'}
            >
              {isConfirm ? 'Confirm new project' : 'Create project'}
            </Button>
          </HStack>
        )}

        <Stack pt={isLargerThan1024 ? '60px' : '0'} pb={'10px'} alignItems={'center'} spacing={'22px'}>
          <SelectReserve
            title={'Reserve assets'}
            symbol={symbol}
            defaultValue={''}
            readonly={isConfirm}
            onCheck={() => (reserveAddress !== '' && !isAddress(reserveAddress)) || symbol === 'Error'}
            onChange={setReserveAddress}
            placeholder={'Input the token contract or select Token'}
            datalist={datalist}
          />

          {!isConfirm && (
            <>
              {channelList.filter((e) => e.token === reserveAddress).length > 0 && (
                <>
                  <SelectOracle
                    title={'Oracle'}
                    onChange={setOracle}
                    placeholder={'Select an Oracle'}
                    datalist={channelList
                      .filter((e) => e.token === reserveAddress)
                      .map((item, index) => ({
                        title: item.title,
                        channelId: item.channelId,
                        pairIndex: item.pairIndex,
                        priceToken: item.priceToken,
                        token: item.token,
                      }))}
                  />
                  <HStack pl={'16px'} w={isLargerThan1024 ? '600px' : 'full'}>
                    <Link
                      href={'https://channel.nestprotocol.org'}
                      isExternal
                      fontWeight={'semibold'}
                      color={'secondary.500'}
                      textDecoration={'underline'}
                    >
                      No suitable oracle? Click to add
                    </Link>
                  </HStack>
                </>
              )}

              {channelList.filter((e) => e.token === reserveAddress).length === 0 && reserveAddress !== '' && symbol !== 'Error' && (
                <HStack pl={'16px'} w={isLargerThan1024 ? '600px' : 'full'}>
                  <Link
                    href={'https://channel.nestprotocol.org'}
                    isExternal
                    fontWeight={'semibold'}
                    color={'secondary.500'}
                    textDecoration={'underline'}
                  >
                    No Oracle? Click here to add
                  </Link>
                </HStack>
              )}
            </>
          )}

          {symbol && symbol !== 'Error' && (
            <>
              <Stack spacing={'16px'} w={isLargerThan1024 ? '600px' : 'full'}>
                <Text fontWeight={'600'} ml={'16px'} fontSize={isLargerThan1024 ? 'md' : 'xs'} color={'secondary.500'}>
                  Stablecoin name:
                </Text>
                <Input value={`Stablecoin for ${symbol}`} isReadOnly/>
              </Stack>

              <Stack spacing={'16px'} w={isLargerThan1024 ? '600px' : 'full'}>
                <Text fontWeight={'600'} ml={'16px'} fontSize={isLargerThan1024 ? 'md' : 'xs'} color={'secondary.500'}>
                  Stablecoin symbol:
                </Text>
                <Input value={`U-${symbol}`} isReadOnly/>
              </Stack>

              <Stack spacing={'16px'} w={isLargerThan1024 ? '600px' : 'full'}>
                <Text fontWeight={'600'} ml={'16px'} fontSize={isLargerThan1024 ? 'md' : 'xs'} color={'secondary.500'}>
                  Mint price:
                </Text>
                <Text fontWeight={'bold'} textAlign={'center'}>
                  1 U-{symbol} = 1 USDT
                </Text>
              </Stack>
            </>
          )}

          {isConfirm ? (
            <Stack spacing={'16px'} w={isLargerThan1024 ? '600px' : 'full'}>
              <Text fontWeight={'600'} ml={'16px'} fontSize={isLargerThan1024 ? 'md' : 'xs'} color={'secondary.500'}>
                APY:
              </Text>
              <Input value={`${parseToBigNumber(apy).toFixed(2)} %`} isReadOnly/>
            </Stack>
          ) : (
            <InputWithSelect
              title={'APY'}
              defaultValue={apy}
              onCheck={() => false}
              onChange={setApy}
              placeholder={'Input APY'}
              unit={'%'}
              readonly={isConfirm}
              min={0}
              max={655}
              isNumber
              datalist={[
                {title: '5 %', data: '5'},
                {title: '10 %', data: '10'},
                {title: '20 %', data: '20'},
              ]}
            />
          )}

          <Stack pt={'24px'} w={'full'} alignItems={'center'}>
            <Button
              w={isLargerThan1024 ? '176px' : '240px'}
              minH={isLargerThan1024 ? '' : '44px'}
              isLoading={status === PROCESSING}
              disabled={!oracle || !isAddress(reserveAddress)}
              onClick={() => {
                if (isConfirm) {
                  create()
                } else {
                  navigate('/p/create?a=confirm')
                }
              }}
            >
              {status === IDLE && <>{isConfirm ? 'Confirm' : 'Create'}</>}
              {status === SUCCESS && 'Success'}
              {status === ERROR && 'Error'}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  )
}

export default Create
