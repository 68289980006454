import {FC, useCallback, useEffect, useState} from 'react'
import {
  Button,
  Collapse,
  FormControl,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useMediaQuery,
} from '@chakra-ui/react'
import { useABCPlatform } from '../../hooks/useContract'
import { ABC_PLATFORM_ADDRESS } from '../../constants/addresses'
import {ERROR, IDLE, IDLE_DELAY, PROCESSING, SUCCESS, ZERO_ADDRESS} from '../../constants/misc'
import {autoFormatNumber, formatNumber, parseToBigNumber} from '../../utils/bignumberUtil'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { ABCProject } from '../../hooks/useAbcProjects'
import TokenIcon from '../../components/TokenIcon'
import useActiveWeb3React from "../../hooks/useActiveWeb3React";
import BigNumber from "bignumber.js";
import {useToken} from "../../hooks/Tokens";

const Repurchase: FC<{ project: ABCProject }> = ({ project }) => {
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [pay, setPay] = useState('')
  const [gain, setGain] = useState('')
  const { isOpen, onToggle } = useDisclosure()
  const { chainId, account } = useActiveWeb3React()
  const abcPlatform = useABCPlatform(ABC_PLATFORM_ADDRESS[chainId ?? 1], true)
  const [status, setStatus] = useState(IDLE)
  const [allowance, setAllowance] = useState(new BigNumber(0))
  const {
    approve: stablecoinApprove,
    allowance: targetAllowance,
    approveStatus: targetApproveStatus,
  } = useToken(project?.stablecoin)

  const repurchase = async () => {
    if (abcPlatform && pay !== '') {
      setStatus(PROCESSING)
      try {
        const q = await abcPlatform.burn(
          parseToBigNumber(project?.index).toNumber(),
          parseToBigNumber(pay).shiftedBy(18).toFixed(0),{
            value: parseToBigNumber(project.singleFee).shiftedBy(18).toFixed()
          }
        )
        const res = await q.wait()
        switch (res.status) {
          case 0:
            setStatus(ERROR)
            setTimeout(() => {
              setStatus(IDLE)
            }, IDLE_DELAY)
            break
          case 1:
            setStatus(SUCCESS)
            setTimeout(() => {
              setStatus(IDLE)
              window.location.reload()
            }, IDLE_DELAY)
            break
          default:
            setStatus(ERROR)
            setTimeout(() => {
              setStatus(IDLE)
            }, IDLE_DELAY)
            break
        }
      } catch (e) {
        setStatus(ERROR)
        setTimeout(() => {
          setStatus(IDLE)
        }, IDLE_DELAY)
      }
    }
  }

  const fetchMyAllowance = useCallback(async () => {
    if (account) {
      setAllowance(await targetAllowance(account, ABC_PLATFORM_ADDRESS[chainId ?? 1]))
    }
  }, [account, chainId, targetAllowance])

  useEffect(() => {
    setGain(
      formatNumber(
        parseToBigNumber(pay)
          .div(project?.price)
          .div(1 + project?.k),
        6
      )
    )
  }, [project?.k, pay, project?.price])

  useEffect(() => {
    fetchMyAllowance()
  }, [fetchMyAllowance])

  return (
    <Stack
      minW={isLargerThan1024 ? 'xl' : ''}
      p={isLargerThan1024 ? '44px' : '22px'}
      alignItems={'center'}
      spacing={5}
      bg={'white'}
      borderRadius={'12px'}
      border={'1px solid'}
      borderColor={'secondary.300'}
    >
      <Stack spacing={'16px'} w={'full'}>
        <Text fontWeight={'600'} ml={'16px'} color={'secondary.500'}>
          Pay
        </Text>
        <FormControl w={'full'}>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              w={'120px'}
              h={'full'}
              children={
                <HStack w={'full'} pl={'22px'}>
                  <TokenIcon symbol={'XUSD'} />
                  <Text fontWeight={'bold'} whiteSpace={'nowrap'}>
                    {project?.stableSymbol}
                  </Text>
                </HStack>
              }
            />
            <Input
              variant={'filled'}
              isInvalid={pay !== '' && (parseToBigNumber(pay).lt(0) || parseToBigNumber(pay).gt(project?.myHoldings))}
              pl={'120px'}
              pr={'70px'}
              textAlign={'right'}
              type={'number'}
              minH={isLargerThan1024 ? '40px' : '44px'}
              fontSize={pay === '' ? '15px' : '17px'}
              errorBorderColor={'primary.500'}
              placeholder={'Input'}
              onChange={(event) => setPay(event.target.value)}
              value={pay}
            />
            <InputRightElement
              pr={'44px'}
              h={'full'}
              children={
                <Button
                  variant={'ghost'}
                  fontWeight={'medium'}
                  onClick={() => {
                    setPay(parseToBigNumber(project?.myHoldings).toFixed())
                  }}
                >
                  MAX
                </Button>
              }
            />
          </InputGroup>
        </FormControl>
        <HStack justifyContent={'end'} pr={'16px'}>
          <Text fontSize={'xs'} color={parseToBigNumber(pay).gt(project.myHoldings) ? 'red' : 'secondary.500'} fontWeight={'medium'}>
            Balance: {formatNumber(project?.myHoldings, 2)} {project?.stableSymbol}
          </Text>
        </HStack>
      </Stack>
      <Stack spacing={'16px'} w={'full'}>
        <Text fontWeight={'600'} ml={'16px'} color={'secondary.500'}>
          Gain(Estimate)
        </Text>
        <FormControl w={'full'}>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              w={'120px'}
              h={'full'}
              children={
                <HStack w={'full'} pl={'22px'}>
                  <TokenIcon symbol={project?.targetSymbol} />
                  <Text fontWeight={'bold'}>{project?.targetSymbol}</Text>
                </HStack>
              }
            />
            <Input
              variant={'filled'}
              pl={'120px'}
              textAlign={'right'}
              isInvalid={parseToBigNumber(gain).gt(project?.reserve)}
              readOnly
              minH={isLargerThan1024 ? '40px' : '44px'}
              fontSize={'17px'}
              errorBorderColor={'primary.500'}
              value={gain}
            />
          </InputGroup>
        </FormControl>
        <HStack justifyContent={'end'} pr={'16px'}>
          <Text fontSize={'xs'} color={parseToBigNumber(gain).gt(project?.reserve) ? 'red' : 'secondary.500'} fontWeight={'medium'}>
            Reserved assets balance: {formatNumber(project?.reserve, 2)} {project?.targetSymbol}
          </Text>
        </HStack>
      </Stack>
      <Stack spacing={'0'} w={'full'}>
        <HStack cursor={'pointer'} color={'secondary.500'} onClick={onToggle}>
          <Text fontWeight={'600'} ml={'16px'}>
            Repurchase price
          </Text>
          <ChevronDownIcon
            transform={isOpen ? 'rotate(-180deg)' : 'rotate(0deg)'}
            transition={'transform 0.2s linear'}
          />
        </HStack>
        <Collapse in={isOpen} animateOpacity>
          <Stack fontWeight={'medium'} fontSize={'xs'} color={'secondary.500'} pt={'16px'} px={'16px'}>
            <HStack justifyContent={'space-between'}>
              <Text>NEST oracle quotation</Text>
              <Text whiteSpace={'nowrap'}>
                {`1 ${project?.targetSymbol} = ${autoFormatNumber(project?.price)} USDT`}
              </Text>
            </HStack>
            <HStack justifyContent={'space-between'}>
              <Text>Stablecoin price</Text>
              <Text>{`1 ${project?.stableSymbol} = 1 USDT`}</Text>
            </HStack>
          </Stack>
        </Collapse>
      </Stack>
      <Tooltip
        label={'The Repurchase price is based on NEST oracle and corrected according to risk compensation.'}
        bg={'white'}
        borderRadius={'12px'}
        color={'link.500'}
      >
        <Text textAlign={'center'} fontWeight={'bold'}>
          {`1 ${project?.targetSymbol} = ${autoFormatNumber(parseToBigNumber(project?.price).div(1 + project?.k))} ${project?.stableSymbol}` }
        </Text>
      </Tooltip>
      <Stack pt={'22px'}>
        {allowance.gte(parseToBigNumber(pay)) ? (
          <Button
            w={isLargerThan1024 ? '160px' : '240px'}
            minH={isLargerThan1024 ? '' : '44px'}
            isLoading={status === PROCESSING}
            disabled={parseToBigNumber(pay).lte(0) || parseToBigNumber(pay).gt(project?.myHoldings)}
            onClick={repurchase}
          >
            {status === IDLE && 'Repurchase'}
            {status === ERROR && 'Error'}
            {status === SUCCESS && 'Success'}
          </Button>
        ) : (
          <Button
            w={isLargerThan1024 ? '160px' : '240px'}
            minH={isLargerThan1024 ? '' : '44px'}
            onClick={async () => {
              await stablecoinApprove(
                ABC_PLATFORM_ADDRESS[chainId ?? 1],
                '0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF'
              )
              await fetchMyAllowance()
            }}
            isLoading={targetApproveStatus === PROCESSING}
          >
            {targetApproveStatus === IDLE && 'Approve'}
            {targetApproveStatus === SUCCESS && 'Success'}
            {targetApproveStatus === ERROR && 'Error'}
          </Button>
        )}
      </Stack>
    </Stack>
  )
}

export default Repurchase
