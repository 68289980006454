import {Button, HStack, Link, Stack, Text, useMediaQuery, Wrap} from '@chakra-ui/react'
import {ArrowBackIcon} from '@chakra-ui/icons'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {ExplorerDataType, getExplorerLink} from '../../utils/getExplorerLink'
import {shortenAddress} from '../../utils'
import {useEffect, useMemo} from 'react'
import Divider from '../../components/Divider'
import {SupportedChainId} from '../../constants/chains'
import {formatNumber, parseToBigNumber} from '../../utils/bignumberUtil'
import Mint from './Mint'
import Repurchase from './Repurchase'
import InformationDetail from './InformationDetail'
import Stake from './Stake'
import useAbcProjects from "../../hooks/useAbcProjects";
import useAbcProject from "../../hooks/useAbcProject";
import TokenIcon from "../../components/TokenIcon";
import useInterval from "@use-it/interval";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";

const Detail = () => {
  const navigate = useNavigate()
  const params = useParams()
  const [searchParams] = useSearchParams()
  const {chainId} = useActiveWeb3React()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const menu = [
    {id: 'Stake', path: 'stake'},
    {id: 'Mint', path: 'mint'},
    {id: 'Repurchase', path: 'repurchase'},
  ]
  const {projects} = useAbcProjects()

  const project = useMemo(() =>
      projects.find((item) => item.index === Number(params.index))
    , [projects, params.index])

  const {fetchAll} = useAbcProject(project)

  useEffect(() => {
    if (!params.action) {
      navigate(`/p/${params.index}/stake?from=${searchParams.get('from')}`)
    }
  }, [navigate, params.action, params.index])

  useEffect(() => {
    fetchAll()
  }, [fetchAll])

  useInterval(()=> {
    fetchAll()
  }, 10000)

  return (
    <Stack h={'full'} px={'22px'} py={isLargerThan1024 ? '22px' : 0} spacing={'12px'}>
      {!isLargerThan1024 && (
        <HStack>
          <Button
            px={0}
            variant={'ghost'}
            onClick={() => navigate('/p')}
            fontWeight={'semibold'}
            color={'secondary.500'}
            leftIcon={<ArrowBackIcon color={'primary.500'} fontSize={'lg'}/>}
          >
            Project detail
          </Button>
        </HStack>
      )}
      {isLargerThan1024 ? (
        <Stack
          bg={'white'}
          p={'22px'}
          borderRadius={'12px'}
          spacing={0}
          minH={'720px'}
          border={'1px solid'}
          borderColor={'secondary.300'}
        >
          <HStack>
            <Button
              variant={'ghost'}
              onClick={() => navigate(`/${searchParams.get('from') ?? 'p'}`)}
              fontWeight={'semibold'}
              color={'secondary.500'}
              leftIcon={<ArrowBackIcon color={'primary.500'} fontSize={'lg'}/>}
            >
              Project detail
            </Button>
          </HStack>
          {
            project && (
              <Wrap px={'44px'} pt={'22px'} justify={"space-between"} w={'full'}>
                <InformationDetail
                  title={'Stablecoin:'}
                  value={project?.stablecoin}
                  label={`${project?.stableSymbol}`}
                  address={getExplorerLink(
                    chainId ?? SupportedChainId.RINKEBY,
                    project?.stablecoin,
                    ExplorerDataType.TOKEN
                  )}
                />
                <InformationDetail
                  title={'Total mint:'}
                  value={formatNumber(project?.totalSupply, 2)}
                />
                <InformationDetail
                  title={'My assets:'}
                  value={formatNumber(project?.myAssets, 2)}
                />
                <InformationDetail
                  title={'Reserved assets:'}
                  address={getExplorerLink(chainId ?? SupportedChainId.RINKEBY, project?.target, ExplorerDataType.TOKEN)}
                  value={project?.target}
                  label={`${formatNumber(project?.reserve, 2)} ${project?.targetSymbol}`}
                />
                <InformationDetail
                  title={'Total stake:'}
                  value={formatNumber(project?.locked, 2)}
                />
                <InformationDetail
                  title={'My stake:'}
                  value={formatNumber(project?.myStake, 2)}
                />
                <InformationDetail
                  title={'APY:'}
                  value={`${formatNumber(project?.apy, 2)}%`}
                />
                <InformationDetail
                  title={'Asset adequacy ratio:'}
                  value={`${formatNumber(parseToBigNumber(project?.aar).shiftedBy(2), 2)} %`}
                />
                <InformationDetail
                  title={'Creator:'}
                  address={getExplorerLink(chainId ?? SupportedChainId.RINKEBY, project?.creator, ExplorerDataType.ADDRESS)}
                  value={project?.creator}
                />
              </Wrap>
              )
          }
          <Stack alignItems={'center'} pt={'44px'} spacing={'22px'} pb={'22px'}>
            <HStack
              w={'xl'}
              justifyContent={'center'}
              border={'2px solid'}
              borderColor={'secondary.300'}
              borderRadius={'12px'}
              h={'34px'}
              spacing={'60px'}
            >
              {menu.map((item) => (
                <Button
                  key={item.id}
                  variant={'ghost'}
                  fontWeight={'semibold'}
                  p={0}
                  color={params.action === item.path ? 'primary.500' : 'secondary.500'}
                  onClick={() => {
                    navigate(`/p/${params.index}/${item.path}?from=${searchParams.get('from')}`)
                  }}
                >
                  {item.id}
                </Button>
              ))}
            </HStack>
            {
              project && (
                <Stack border={'1px solid'} borderColor={'secondary.300'} borderRadius={'12px'}>
                  {params.action === 'stake' && <Stake project={project}/>}
                  {params.action === 'mint' && <Mint project={project}/>}
                  {params.action === 'repurchase' && <Repurchase project={project}/>}
                </Stack>
                )
            }
          </Stack>
        </Stack>
      ) : (
        <Stack spacing={'22px'}>
          {
            project && (
              <Stack bg={'white'} p={'22px'} borderRadius={'12px'} border={'1px solid'} borderColor={'secondary.300'}>
                <HStack justifyContent={'space-between'}>
                  <Text fontWeight={'semibold'} color={'secondary.500'}>
                    Stablecoin:
                  </Text>
                  <Link
                    href={getExplorerLink(chainId ?? SupportedChainId.RINKEBY, project?.stablecoin, ExplorerDataType.TOKEN)}
                    isExternal
                  >
                    <HStack>
                      <TokenIcon symbol={'XUSD'}/>
                      <Text fontWeight={'bold'} color={'#0047BB'}>{`${project?.stableSymbol}`}</Text>
                    </HStack>
                  </Link>
                </HStack>
                <Divider/>
                <HStack justifyContent={'space-between'}>
                  <Text fontWeight={'semibold'} color={'secondary.500'}>
                    APY:
                  </Text>
                  <Text fontWeight={'bold'}>{`${formatNumber(project?.apy, 2)}%`}</Text>
                </HStack>
                <Divider/>
                <HStack justifyContent={'space-between'}>
                  <Text fontWeight={'semibold'} color={'secondary.500'}>
                    Creator:
                  </Text>
                  <Link
                    href={getExplorerLink(chainId ?? SupportedChainId.RINKEBY, project?.creator, ExplorerDataType.ADDRESS)}
                    isExternal
                  >
                    <Text fontWeight={'bold'} color={'#0047BB'}>{shortenAddress(project?.creator)}</Text>
                  </Link>
                </HStack>
                <Divider/>
                <HStack justifyContent={'space-between'}>
                  <Text fontWeight={'semibold'} color={'secondary.500'}>
                    Total mint:
                  </Text>
                  <Text fontWeight={'bold'}>{formatNumber(project?.totalSupply, 2)}</Text>
                </HStack>
                {parseToBigNumber(project?.myAssets).gt(0) && (
                  <HStack justifyContent={'space-between'}>
                    <Text fontWeight={'semibold'} color={'secondary.500'}>
                      My assets:
                    </Text>
                    <Text fontWeight={'bold'}>{formatNumber(project?.myAssets, 2)}</Text>
                  </HStack>
                )}
                <Divider/>
                <HStack justifyContent={'space-between'}>
                  <Text fontWeight={'semibold'} color={'secondary.500'}>
                    Reserved assets:
                  </Text>
                  <Link
                    href={getExplorerLink(chainId ?? SupportedChainId.RINKEBY, project?.target, ExplorerDataType.TOKEN)}
                    isExternal
                  >
                    <HStack>
                      <TokenIcon symbol={project?.targetSymbol}/>
                      <Text fontWeight={'bold'}
                            color={'#0047BB'}>{`${formatNumber(project?.reserve, 2)} ${project?.targetSymbol}`}</Text>
                    </HStack>
                  </Link>
                </HStack>
                <Divider/>
                <HStack justifyContent={'space-between'}>
                  <Text fontWeight={'semibold'} color={'secondary.500'}>
                    Total stake:
                  </Text>
                  <Text fontWeight={'bold'}>{formatNumber(project?.locked, 2)}</Text>
                </HStack>
                {parseToBigNumber(project?.myStake).gt(0) && (
                  <HStack justifyContent={'space-between'}>
                    <Text fontWeight={'semibold'} color={'secondary.500'}>
                      My stake:
                    </Text>
                    <Text fontWeight={'bold'}>{formatNumber(project?.myStake, 2)}</Text>
                  </HStack>
                )}
                <Divider/>
                <HStack justifyContent={'space-between'}>
                  <Text fontWeight={'semibold'} color={'secondary.500'}>
                    Asset adequacy ratio:
                  </Text>
                  <Text fontWeight={'bold'}>{`${formatNumber(parseToBigNumber(project?.aar).shiftedBy(2), 2)} %`}</Text>
                </HStack>
              </Stack>
            )
          }
          <HStack
            bg={'white'}
            py={'11px'}
            borderRadius={'12px'}
            border={'1px solid'}
            justifyContent={'space-around'}
            borderColor={'secondary.300'}
          >
            {menu.map((item) => (
              <Button
                key={item.id}
                variant={'ghost'}
                fontWeight={'semibold'}
                p={0}
                color={params.action === item.path ? 'primary.500' : 'secondary.500'}
                onClick={() => {
                  navigate(`/p/${params.index}/${item.path}`)
                }}
              >
                {item.id}
              </Button>
            ))}
          </HStack>
          { project && (
            <Stack border={'1px solid'} borderColor={'secondary.300'} borderRadius={'12px'}>
              {params.action === 'stake' && <Stake project={project}/>}
              {params.action === 'mint' && <Mint project={project}/>}
              {params.action === 'repurchase' && <Repurchase project={project}/>}
            </Stack>
          ) }
        </Stack>
      )}
    </Stack>
  )
}

export default Detail
