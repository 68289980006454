import { BigNumberish } from 'ethers'
import { FC } from 'react'
import { Link, Skeleton, Spacer, Stack, Text, Tooltip, useMediaQuery, WrapItem } from '@chakra-ui/react'
import { shortenAddress } from '../../utils'

type InformationDetailProps = {
  title: string
  value: string | number | undefined | BigNumberish
  unit?: string
  loading?: boolean
  address?: string
  label?: string
}

const InformationDetail: FC<InformationDetailProps> = ({ ...props }) => {
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')

  if (props.value === undefined || props.loading) {
    return (
      <WrapItem w={isLargerThan1024 ? '300px' : 'full'}>
        <Stack direction={'row'} w={'full'}>
          <Text color={'secondary.500'} fontWeight={'600'} whiteSpace={'nowrap'}>
            {props.title}
          </Text>
          <Spacer />
          <Skeleton w={'100px'} />
        </Stack>
      </WrapItem>
    )
  }

  return (
    <WrapItem w={isLargerThan1024 ? '300px' : 'full'}>
      <Stack direction={'row'} w={'full'}>
        <Tooltip label={props.title} bg={'white'} borderRadius={'full'} color={'link.500'}>
          <Text
            color={'secondary.500'}
            fontWeight={'600'}
            whiteSpace={'nowrap'}
            overflow={'hidden'}
            textOverflow={'ellipsis'}
            w={'180px'}
            cursor={'pointer'}
          >
            {props.title}
          </Text>
        </Tooltip>
        <Spacer />
        {props.address ? (
          <Tooltip
            label={shortenAddress(String(props.value))}
            bg={'white'}
            borderRadius={'full'}
            color={'link.500'}
            whiteSpace={'nowrap'}
          >
            <Link href={props.address} isExternal color={'link.600'} fontWeight={'bold'}>
              <Text whiteSpace={'nowrap'}>{props.label ?? shortenAddress(String(props.value))}</Text>
            </Link>
          </Tooltip>
        ) : (
          <Text fontWeight={'bold'} whiteSpace={'nowrap'} textOverflow={'ellipsis'}>
            {props.label ?? props.value} {props.unit}
          </Text>
        )}
      </Stack>
    </WrapItem>
  )
}

export default InformationDetail
