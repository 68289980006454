import {
  Button,
  HStack,
  Input,
  Link,
  Select,
  Spacer,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead, Tooltip,
  Tr,
  useMediaQuery,
  VStack,
} from '@chakra-ui/react'
import { ArrowBackIcon, ArrowForwardIcon, ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { useEffect, useMemo, useState } from 'react'
import {formatNumber, parseToBigNumber} from '../../utils/bignumberUtil'
import useAbcProjects, { ABCProject } from '../../hooks/useAbcProjects'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'
import { useNavigate } from 'react-router-dom'
import { atom, useRecoilState } from 'recoil'
import { pageSizeAtom } from '../project'
import TokenIcon from "../../components/TokenIcon";
import NoResult from "../../assets/svg/NoResults.svg";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";

const currentPageAtom = atom({
  key: 'abc:current:assets:page',
  default: 1,
})

const sortKeyAtom = atom({
  key: 'abc:assets:sortkey',
  default: 'myAssets',
})

const sortAtom = atom({
  key: 'abc:assets:sort',
  default: false,
})

const Assets = () => {
  const [pageSize, setPageSize] = useRecoilState(pageSizeAtom)
  const [currentPage, setCurrentPage] = useRecoilState(currentPageAtom)
  const [inputPage, setInputPage] = useState('')
  const { chainId } = useActiveWeb3React()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const { projects, fetchAllProjects } = useAbcProjects()
  const navigate = useNavigate()
  const [sortKey, setSortKey] = useRecoilState(sortKeyAtom)
  const [sort, setSort] = useRecoilState(sortAtom)

  const compare = (key: string, sort: boolean) => {
    return function (m: ABCProject, n: ABCProject) {
      // @ts-ignore
      const a = m[key]
      // @ts-ignore
      const b = n[key]
      if (sort) {
        if (isNaN(a - b)) {
          return a.localeCompare(b)
        }
        return a - b
      } else {
        if (isNaN(b - a)) {
          return b.localeCompare(a)
        }
        return b - a
      }
    }
  }

  const heads = [
    { id: 1, label: 'Stablecoin', key: 'stablecoin', tips: 'Stablecoin'},
    { id: 2, label: 'Assets', key: 'myAssets', tips: 'Assets' }, // 总资产
    { id: 3, label: 'My stake', key: 'myStake', tips: 'My stake' }, // 质押的数量
    { id: 4, label: 'Principal and interest balance', key: 'myPandIBalance', tips: 'Principal and interest balance' }, // 质押+活期
    { id: 5, label: 'Holdings', key: 'myHoldings', tips: 'Holdings' }, // 钱包余额
  ]

  const currentPageProjects = useMemo(() => {
    return projects
      .filter((item) => item.chainId === (chainId ?? 1))
      .filter((item) => parseToBigNumber(item.myAssets).gt(0))
      .sort(compare(sortKey, sort))
      .filter((item, index) => Math.ceil((index + 1) / pageSize) === currentPage)
  }, [currentPage, pageSize, projects, sortKey, sort])

  const maxPages = useMemo(
    () =>
      Math.ceil(currentPageProjects.length / pageSize) >= 1 ? Math.ceil(currentPageProjects.length / pageSize) : 1,
    [currentPageProjects.length, pageSize]
  )

  useEffect(() => {
    if (inputPage && Number(inputPage) > 0 && Number(inputPage) <= maxPages) {
      setCurrentPage(Number(inputPage))
    }
  }, [inputPage, maxPages])

  useEffect(() => {
    fetchAllProjects()
  }, [fetchAllProjects])

  return (
    <Stack h={'full'} px={'22px'} py={isLargerThan1024 ? '22px' : 0} spacing={'22px'}>
      { currentPageProjects.length > 0 ? (
        <>
          {isLargerThan1024 ? (
            <TableContainer
              bg={'white'}
              borderRadius={12}
              h={'full'}
              border={'1px solid'}
              borderColor={'secondary.300'}
              px={4}
            >
              <Table>
                <Thead>
                  <Tr>
                    <Th />
                    {heads.map((item) => (
                      <Th
                        textTransform={'none'}
                        color={'secondary.500'}
                        fontSize={'sm'}
                        fontWeight={'semibold'}
                        fontFamily={'Montserrat'}
                        key={item.id}
                        cursor={'pointer'}
                        userSelect={'none'}
                      >
                        <Tooltip
                          label={item.tips}
                          bg={'white'}
                          borderRadius={'full'}
                          color={'link.500'}
                          whiteSpace={'nowrap'}
                        >
                          <HStack
                            spacing={'6px'}
                            onClick={() => {
                              setSort(sortKey === item.key ? !sort : false)
                              setSortKey(item.key)
                            }}
                          >
                            <Text
                              color={sortKey === item.key ? '#00B7EE' : ''}
                            >
                              {item.label}
                            </Text>
                            <VStack spacing={'0'}>
                              <ChevronUpIcon
                                color={sortKey === item.key && sort ? '#00B7EE' : ''}
                              />
                              <ChevronDownIcon
                                color={sortKey === item.key && !sort ? '#00B7EE' : ''}
                              />
                            </VStack>
                          </HStack>
                        </Tooltip>
                      </Th>
                    ))}
                    <Th />
                  </Tr>
                </Thead>
                <Tbody>
                  {currentPageProjects.map((project) => (
                    <Tr
                      key={project?.index}
                      fontSize={'xs'}
                      fontWeight={'semibold'}
                      _hover={{ bg: '#F7F8FA' }}
                      onClick={() => {
                        navigate(`/p/${project?.index}?from=assets`)
                      }}
                      cursor={'pointer'}
                    >
                      <Td />
                      <Td>
                        <Link
                          href={getExplorerLink(chainId ?? 1, project?.stablecoin, ExplorerDataType.TOKEN)}
                          isExternal
                          color={'#0082AA'}
                        >
                          <HStack>
                            <TokenIcon symbol={'XUSD'} />
                            <Text fontWeight={'bold'}>{`U-${project?.targetSymbol}`}</Text>
                          </HStack>
                        </Link>
                      </Td>
                      <Td>{formatNumber(project?.myAssets, 2)}</Td>
                      <Td>{formatNumber(project?.myStake, 2)}</Td>
                      <Td>{formatNumber(project?.myPandIBalance, 2)}</Td>
                      <Td>{formatNumber(project?.myHoldings, 2)}</Td>
                      <Td />
                    </Tr>
                  ))}
                </Tbody>
              </Table>
              <HStack py={2} px={'12px'} fontSize={'sm'} fontWeight={'semibold'} color={'secondary.500'}>
                <Text>Total {formatNumber(currentPageProjects.length)} Assets</Text>
                <Select
                  h={7}
                  w={48}
                  fontSize={'xs'}
                  border={'1px'}
                  onChange={(e) => {
                    setPageSize(Number(e.target.value))
                    localStorage.setItem('abc:pageSize', e.target.value)
                  }}
                >
                  {[10, 25, 50, 100].map((item) => (
                    <option key={item} value={item}>
                      {item} projects/page
                    </option>
                  ))}
                </Select>
                {maxPages > 1 && (
                  <>
                    <Spacer />
                    <Button
                      variant={'ghost'}
                      disabled={currentPage === 1}
                      onClick={() => {
                        if (currentPage > 1) {
                          setCurrentPage(currentPage - 1)
                        }
                      }}
                    >
                      <ArrowBackIcon />
                    </Button>
                    {[1, 2, 3, 4, 5].map((item) => (
                      <Button
                        key={item}
                        variant={currentPage === item ? 'solid' : 'ghost'}
                        color={currentPage === item ? 'black' : 'secondary.500'}
                        size={'xs'}
                        onClick={() => setCurrentPage(item)}
                        hidden={item > maxPages}
                      >
                        {item}
                      </Button>
                    ))}

                    <Button
                      variant={'ghost'}
                      m={0}
                      ml={0}
                      disabled={currentPage === maxPages}
                      onClick={() => {
                        if (currentPage < maxPages) {
                          setCurrentPage(currentPage + 1)
                        }
                      }}
                    >
                      <ArrowForwardIcon />
                    </Button>
                    <Text color={'black'}>go to</Text>
                    <Input
                      maxH={7}
                      px={0}
                      textAlign={'center'}
                      maxW={'36px'}
                      value={inputPage}
                      type={'number'}
                      onChange={(e) => setInputPage(e.target.value)}
                    />
                  </>
                )}
              </HStack>
            </TableContainer>
          ) : (
            <Stack spacing={'22px'} h={'full'}>
              {currentPageProjects.map((project) => (
                <Stack
                  key={project?.index}
                  bg={'white'}
                  p={5}
                  borderRadius={'12px'}
                  border={'1px solid'}
                  borderColor={'secondary.300'}
                  onClick={() => {
                    navigate(`/p/${project?.index}?from=assets`)
                  }}
                >
                  <HStack justifyContent={'space-between'}>
                    <Text fontWeight={'semibold'} color={'secondary.500'}>
                      Stablecoin
                    </Text>
                    <Link
                      href={getExplorerLink(chainId ?? 1, project?.stablecoin, ExplorerDataType.TOKEN)}
                      isExternal
                      color={'#0082AA'}
                    >
                      <HStack>
                        <TokenIcon symbol={'XUSD'} />
                        <Text fontWeight={'bold'}>{`U-${project?.targetSymbol}`}</Text>
                      </HStack>
                    </Link>
                  </HStack>

                  <HStack justifyContent={'space-between'}>
                    <Text fontWeight={'semibold'} color={'secondary.500'}>
                      Asset
                    </Text>
                    <Text fontWeight={'bold'}>{formatNumber(project?.myAssets, 2)}</Text>
                  </HStack>

                  <HStack justifyContent={'space-between'}>
                    <Text fontWeight={'semibold'} color={'secondary.500'}>
                      My stake
                    </Text>
                    <Text fontWeight={'bold'}>{formatNumber(project?.myStake, 2)}</Text>
                  </HStack>

                  <HStack justifyContent={'space-between'}>
                    <Text fontWeight={'semibold'} color={'secondary.500'}>
                      Principal and interest balance
                    </Text>
                    <Text fontWeight={'bold'}>{formatNumber(project?.myPandIBalance, 2)}</Text>
                  </HStack>

                  <HStack justifyContent={'space-between'}>
                    <Text fontWeight={'semibold'} color={'secondary.500'}>
                      Holdings
                    </Text>
                    <Text fontWeight={'bold'}>{formatNumber(project?.myHoldings, 2)}</Text>
                  </HStack>
                </Stack>
              ))}
              <Spacer />
              {maxPages > 1 && (
                <HStack w={'full'} justifyContent={'space-between'}>
                  <Button
                    variant={'ghost'}
                    disabled={currentPage === 1}
                    onClick={() => {
                      if (currentPage > 1) {
                        setCurrentPage(currentPage - 1)
                      }
                    }}
                  >
                    <ArrowBackIcon fontSize={'lg'} />
                  </Button>
                  {[1, 2, 3, 4, 5].map((item) => (
                    <Button
                      key={item}
                      variant={currentPage === item ? 'solid' : 'ghost'}
                      color={currentPage === item ? 'black' : 'secondary.500'}
                      onClick={() => setCurrentPage(item)}
                      size={'sm'}
                      hidden={item > maxPages}
                    >
                      {item}
                    </Button>
                  ))}
                  <Button
                    variant={'ghost'}
                    m={0}
                    ml={0}
                    disabled={currentPage === maxPages}
                    onClick={() => {
                      if (currentPage < maxPages) {
                        setCurrentPage(currentPage + 1)
                      }
                    }}
                  >
                    <ArrowForwardIcon fontSize={'lg'} />
                  </Button>
                </HStack>
              )}
            </Stack>
          )}
        </>
      ) : (
        <Stack
          w={'full'}
          h={isLargerThan1024 ? '640px' : '400px'}
          alignItems={"center"}
          justify={"center"}
          bg={'white'}
          borderRadius={'12px'}
          spacing={'40px'}
        >
          <img src={NoResult} width={'125px'}/>
          <HStack>
            <Text fontWeight={'semibold'}>No asset yet</Text>
            <Text color={'#00B7EE'} fontWeight={'semibold'} cursor={"pointer"} onClick={() => {
              navigate('/p')
            }}>go to participate</Text>
          </HStack>
        </Stack>
      ) }
    </Stack>
  )
}

export default Assets
