import { FC } from 'react'
import XUSD from '../../assets/svg/XUSD.svg'
import NEST from '../../assets/svg/NEST.svg'
import PETH from '../../assets/svg/PETH.svg'
import PBTC from '../../assets/svg/PBTC.svg'
import PUSD from '../../assets/svg/PUSD.svg'

type TokenIconProps = {
  symbol: string
  size?: string
}

const TokenIcon: FC<TokenIconProps> = ({ ...props }) => {
  if (props.symbol === 'XUSD') {
    return <img src={XUSD} alt={''} height={props.size ?? '20px'} width={props.size ?? '20px'} />
  } else if (props.symbol === 'NEST') {
    return <img src={NEST} alt={''} height={props.size ?? '20px'} width={props.size ?? '20px'} />
  } else if (props.symbol === 'PETH') {
    return <img src={PETH} alt={''} height={props.size ?? '20px'} width={props.size ?? '20px'} />
  } else if (props.symbol === 'PBTC') {
    return <img src={PBTC} alt={''} height={props.size ?? '20px'} width={props.size ?? '20px'} />
  } else if (props.symbol === 'PUSD') {
    return <img src={PUSD} alt={''} height={props.size ?? '20px'} width={props.size ?? '20px'} />
  }

  return <img src={`/tokens/${props.symbol}.png`} height={props.size ?? '20px'} width={props.size ?? '20px'} alt={""}/>
}

export default TokenIcon
