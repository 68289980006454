import {useTokenContract} from './useContract'
import {useCallback, useMemo, useState} from 'react'
import {ERROR, IDLE, IDLE_DELAY, PROCESSING, SUCCESS, ZERO_ADDRESS} from '../constants/misc'
import {parseToBigNumber} from '../utils/bignumberUtil'
import BigNumber from 'bignumber.js'
import useActiveWeb3React from "./useActiveWeb3React";

export const useToken = (tokenAddress: string | undefined) => {
  const { library } = useActiveWeb3React()
  const contract = useTokenContract(tokenAddress, true)
  const [approveStatus, setApproveStatus] = useState(IDLE)

  const decimal = useMemo(async () => {
    if (tokenAddress === ZERO_ADDRESS) {
      return 18
    }
    try {
      return (await contract?.decimals()) ?? 18
    } catch (e) {
      return 18
    }
  }, [contract, tokenAddress])

  const totalSupply = useMemo(async () => {
    try {
      const _decimal = await decimal
      return parseToBigNumber(await contract?.totalSupply() ?? 0).shiftedBy(-_decimal)
    } catch (e) {
      return 0
    }
  }, [contract, decimal])

  const symbol = useMemo(async () => await contract?.symbol(), [contract])

  const balanceOf = useCallback(
    async (account: string) => {
      if (tokenAddress === ZERO_ADDRESS) {
        return parseToBigNumber((await library?.getBalance(account)) ?? new BigNumber(NaN)).shiftedBy(-18)
      }
      try {
        const _decimal = await decimal
        return parseToBigNumber((await contract?.balanceOf(account)) ?? new BigNumber(NaN)).shiftedBy(-_decimal)
      } catch (e) {
        return new BigNumber(0)
      }
    },
    [contract, decimal, library, tokenAddress]
  )

  const allowance = useCallback(
    async (owner: string, spender: string) => {
      try {
        const _decimal = await decimal
        return parseToBigNumber((await contract?.allowance(owner, spender)) ?? new BigNumber(NaN)).shiftedBy(-_decimal)
      } catch (e) {
        return new BigNumber(0)
      }
    },
    [contract, decimal]
  )

  const approve = useCallback(
    async (spender: string, value: string) => {
      setApproveStatus(PROCESSING)
      try {
        const tx = await contract?.approve(spender, value)
        if (!tx) return
        const res = await tx.wait()
        switch (res.status) {
          case 0:
            setApproveStatus(ERROR)
            setTimeout(() => {
              setApproveStatus(IDLE)
            }, IDLE_DELAY)
            break
          case 1:
            setApproveStatus(SUCCESS)
            setTimeout(() => {
              setApproveStatus(IDLE)
            }, IDLE_DELAY)
            break
        }
      } catch (e) {
        setApproveStatus(ERROR)
        setTimeout(() => {
          setApproveStatus(IDLE)
        }, IDLE_DELAY)
      }
    },
    [contract]
  )

  return {
    symbol,
    decimal,
    totalSupply,
    allowance,
    balanceOf,
    approve,
    approveStatus,
  }
}
