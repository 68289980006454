import {
  Box,
  Button,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import Divider from '../Divider'
import {FC, useCallback, useEffect, useRef, useState} from 'react'
import {BiChevronDownCircle} from 'react-icons/all'
import useWindowSize from '../../hooks/useWindowSize'
import {ChannelInfo} from '../../hooks/useNestPlatform'
import {atom, useRecoilState} from 'recoil'

type OptionInput = {
  title: string
  onChange: (item: ChannelInfo) => void
  datalist: ChannelInfo[]
  placeholder?: string
}

const valueAtom = atom({
  key: 'abc:create:channel:title',
  default: '',
})

const SelectOracle: FC<OptionInput> = ({...props}) => {
  const [showOption, setShowOption] = useState(false)
  const [value, setValue] = useRecoilState(valueAtom)
  const inputRef = useRef(null)
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const {width} = useWindowSize()

  const cleanValue = useCallback(async () => {
    const res = props.datalist.find((item) => item.title === value)
    if (res === undefined) {
      setValue('')
    } else {
      setValue(value)
    }
  }, [props.datalist, value])

  useEffect(() => {
    cleanValue()
  }, [cleanValue])

  useEffect(() => {
    if (props.datalist.length === 1) {
      setValue(props.datalist[0].title)
    }
  }, [props.datalist])

  return (
    <Box pb={showOption ? (isLargerThan1024 ? '40px' : '44px') : '0'} w={isLargerThan1024 ? '600px' : 'full'}>
      <Text
        fontWeight={'600'}
        mb={isLargerThan1024 ? '16px' : '10px'}
        fontSize={isLargerThan1024 ? 'md' : 'xs'}
        ml={'16px'}
        color={'secondary.500'}
      >
        {props.title}:
      </Text>
      <FormControl
        bg={'white'}
        width={isLargerThan1024 ? 600 : width - 66}
        borderRadius={showOption ? '10px' : '0'}
        border={showOption ? '1px' : '0'}
        borderColor={'primary.500'}
        pos={showOption ? 'absolute' : 'static'}
        zIndex={showOption ? 10 : 0}
      >
        <InputGroup>
          <Input
            id={'amount'}
            ref={inputRef}
            minH={isLargerThan1024 ? '40px' : '44px'}
            variant={showOption ? 'unstyled' : 'filled'}
            errorBorderColor={'primary.500'}
            placeholder={props?.placeholder || ''}
            fontSize={value === '' ? '15px' : '17px'}
            cursor={'pointer'}
            readOnly
            value={value}
            onFocus={(e) => {
              setShowOption(true)
            }}
            onBlur={() => {
              setTimeout(() => setShowOption(false), 200)
            }}
          />
          <InputRightElement
            onClick={() => {
              if (!showOption) {
                // @ts-ignore
                inputRef.current.focus()
              }
            }}
            cursor={'pointer'}
            h={isLargerThan1024 ? '40px' : '44px'}
            children={
              <Stack pr={'12px'}>
                <BiChevronDownCircle size={isLargerThan1024 ? '22px' : '16px'} color={'#878787'}/>
              </Stack>
            }
          />
        </InputGroup>

        {props.datalist.length > 0 && (
          <Stack hidden={!showOption} pb={1} top={'72px'} spacing={0}>
            <Divider/>
            {props.datalist.map((item, index) => (
              <Button
                key={index}
                variant={'ghost'}
                justifyContent={'flex-start'}
                fontWeight={'600'}
                fontSize={'17px'}
                borderRadius={0}
                onClick={() => {
                  setValue(item.title)
                  props.onChange(item)
                  setShowOption(false)
                }}
                _hover={{bg: 'secondary.400'}}
              >
                {item.title}
              </Button>
            ))}
          </Stack>
        )}
      </FormControl>
    </Box>
  )
}

export default SelectOracle
