import * as React from 'react'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { Link, Spacer, Stack, useMediaQuery, Button, HStack } from '@chakra-ui/react'
import { Logo } from '../components/Logo'
import Web3ReactManager from '../components/Web3ReactManager'
import NetworkCard from '../components/NetworkCard'
import Project from './project'
import Assets from './assets'
import Web3Status from '../components/Web3Status'
import Create from './project/create'
import Detail from './project/detail'
import { useEffect } from 'react'

export const App = () => {
  return (
    <Web3ReactManager>
      <Stack spacing={0} w={'full'} h={'full'} alignItems={'center'} pb={5}>
        <Header />
        <Content />
      </Stack>
    </Web3ReactManager>
  )
}

const Header = () => {
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const location = useLocation()
  const navigate = useNavigate()

  const menu = [
    { label: 'Project List', path: '/p' },
    { label: 'My Assets', path: '/assets' },
  ]

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/p')
    }
  }, [location.pathname, navigate])

  return (
    <Stack
      w={isLargerThan1024 ? 'container.xl' : 'full'}
      px={'20px'}
      pt={'20px'}
      pb={isLargerThan1024 ? '20px' : '12px'}
      spacing={'20px'}
      alignItems={'center'}
      borderBottom={isLargerThan1024 ? '' : '1px solid'}
      borderColor={isLargerThan1024 ? '' : 'secondary.400'}
      mb={isLargerThan1024 ? '' : '20px'}
    >
      <Stack direction={'row'} alignItems={'center'} w={'full'} spacing={'22px'}>
        <Link href={'/'}>
          <Logo h={isLargerThan1024 ? '28px' : '24px'} />
        </Link>
        <Spacer />
        {isLargerThan1024 && (
          <>
            {menu.map((item) => (
              <Button
                key={item.label}
                variant={'ghost'}
                p={0}
                color={location.pathname === item.path ? 'primary.500' : ''}
                onClick={() => {
                  navigate(item.path)
                }}
              >
                {item.label}
              </Button>
            ))}
          </>
        )}
        <NetworkCard />
        {isLargerThan1024 && <Web3Status />}
      </Stack>
      {!isLargerThan1024 && (
        <Stack w={'240px'} spacing={'12px'}>
          <Web3Status />
          <HStack justifyContent={'center'} spacing={'22px'}>
            {menu.map((item) => (
              <Button
                key={item.label}
                variant={'ghost'}
                p={0}
                color={location.pathname === item.path ? 'primary.500' : ''}
                onClick={() => {
                  navigate(item.path)
                }}
              >
                {item.label}
              </Button>
            ))}
          </HStack>
        </Stack>
      )}
    </Stack>
  )
}

const Content = () => {
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')

  return (
    <Stack
      background={isLargerThan1024 ? 'rgba(255,255,255, 0.5)' : ''}
      borderRadius={12}
      w={isLargerThan1024 ? 'container.xl' : 'full'}
      h={'full'}
    >
      <Routes>
        <Route path="/" element={<Project />} />
        <Route path="/p" element={<Project />} />
        <Route path="/p/create" element={<Create />} />
        <Route path="/p/:index" element={<Detail />} />
        <Route path="/p/:index/:action" element={<Detail />} />
        <Route path="/assets" element={<Assets />} />
      </Routes>
    </Stack>
  )
}
