import {useCallback, useEffect} from 'react'
import { atom, useRecoilState } from 'recoil'
import useInterval from "@use-it/interval";
import useActiveWeb3React from "./useActiveWeb3React";

export type ABCProject = {
  index: number // project index
  time: string // create time
  channelId: number
  pairIndex: number
  target: string
  targetSymbol: string
  stableSymbol: string
  sigmaSQ: number
  k: number
  creator: string
  stablecoin: string
  totalSupply: string
  circulation: string
  locked: string
  myAssets: string
  myStake: string
  myEarned: string
  myPandIBalance: string
  myHoldings: string
  price: string
  singleFee: number
  unit: number
  apy: number // stakingRewardRate
  reserve: string
  aar: number
  chainId: number
}

const defaultABCProjectsAtom: ABCProject[] = []

const ABCProjectsAtom = atom({
  key: 'abc:projects',
  default: defaultABCProjectsAtom,
})

const ABCProjectCountAtom = atom({
  key: 'abc:projects:count',
  default: 0,
})

const useAbcProjects = () => {
  const { chainId, account } = useActiveWeb3React()
  const [projects, setProjects] = useRecoilState(ABCProjectsAtom) // store all project information
  const [count, setCount] = useRecoilState(ABCProjectCountAtom) // count of projects

  const fetchProjectsCount = useCallback(async () => {
    setCount(projects.length)
  }, [setCount, projects])

  const fetchAllProjects = useCallback(async () => {
    if (!chainId) {
      return
    }
    try {
      const q = await fetch(`https://www.nestdapp.io/dabs/projectList?chainId=${chainId}`)
      const res = await q.json()
      if (res.message === 'ok') {
        const newProjects: ABCProject[] = res.value.map((item: any) => ({
          index: item.index,
          time: item.time,
          channelId: item.channelId,
          pairIndex: item.pairIndex,
          target: item.target,
          targetSymbol: item.targetSymbol,
          stableSymbol: item.stableSymbol,
          sigmaSQ: item.sigmaSQ ?? 0,
          k: item.k ?? 0,
          creator: item.creator,
          stablecoin: item.stablecoin,
          totalSupply: item.totalSupply,
          circulation: item.circulation,
          locked: item.locked,
          myAssets: projects[item.index]?.myAssets ?? '0',
          myStake:  projects[item.index]?.myStake ?? '0',
          myEarned: projects[item.index]?.myEarned ?? '0',
          myPandIBalance: projects[item.index]?.myPandIBalance ?? '0',
          myHoldings: projects[item.index]?.myHoldings ?? '0',
          price: item.price,
          singleFee: item.singleFee ?? 0,
          unit: item.postUnit ?? 2000,
          apy: item.apy, // stakingRewardRate
          reserve: item.reserve,
          aar: item.aar ?? 0,
          chainId: item.chainId
        }))
        setProjects(newProjects)
      }
    } catch (e) {
      console.log(e)
    }
  }, [chainId, account])

  useEffect(() => {
    fetchAllProjects()
  }, [fetchAllProjects])

  useInterval(()=> {
    fetchAllProjects()
  }, 60000)

  return {
    count,
    projects,
    setProjects,
    fetchProjectsCount,
    fetchAllProjects,
  }
}

export default useAbcProjects
