import { FC, useCallback, useEffect, useState } from 'react'
import {
  Button,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  Stack,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import BigNumber from 'bignumber.js'
import { useToken } from '../../hooks/Tokens'
import { useABCPlatform } from '../../hooks/useContract'
import { ABC_PLATFORM_ADDRESS } from '../../constants/addresses'
import { ERROR, IDLE, IDLE_DELAY, PROCESSING, SUCCESS } from '../../constants/misc'
import { formatNumber, parseToBigNumber } from '../../utils/bignumberUtil'
import Divider from '../../components/Divider'
import { ABCProject } from '../../hooks/useAbcProjects'
import TokenIcon from "../../components/TokenIcon";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";

const Stake: FC<{ project: ABCProject }> = ({ project }) => {
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isStake, setIsStake] = useState(false)
  const [stakeAmount, setStakeAmount] = useState('')
  const { chainId, account } = useActiveWeb3React()
  const {
    allowance: stableAllowance,
    approve: stableApprove,
    approveStatus: stableApproveStatus,
  } = useToken(project?.stablecoin)
  const abcPlatform = useABCPlatform(ABC_PLATFORM_ADDRESS[chainId ?? 1], true)
  const [stakeStatus, setStakeStatus] = useState(IDLE)
  const [withdrawStatus, setWithdrawStatus] = useState(IDLE)
  const [allowance, setAllowance] = useState(new BigNumber(0))

  const fetchMyAllowance = useCallback(async () => {
    if (account) {
      setAllowance(await stableAllowance(account, ABC_PLATFORM_ADDRESS[chainId ?? 1]))
    }
  }, [account, chainId, stableAllowance])

  useEffect(()=>{
    fetchMyAllowance()
  }, [fetchMyAllowance])

  const stake = async () => {
    if (abcPlatform && parseToBigNumber(stakeAmount).gt(0)) {
      setStakeStatus(PROCESSING)
      try {
        const q = await abcPlatform.stake(
          parseToBigNumber(project?.index).toNumber(),
          parseToBigNumber(stakeAmount).shiftedBy(18).toFixed(0)
        )
        const res = await q.wait()
        switch (res.status) {
          case 0:
            setStakeStatus(ERROR)
            setTimeout(() => {
              setStakeStatus(IDLE)
            }, IDLE_DELAY)
            break
          case 1:
            setStakeStatus(SUCCESS)
            setTimeout(() => {
              setStakeStatus(IDLE)
              window.location.reload()
            }, IDLE_DELAY)
            break
          default:
            setStakeStatus(ERROR)
            setTimeout(() => {
              setStakeStatus(IDLE)
            }, IDLE_DELAY)
            break
        }
      } catch (e) {
        setStakeStatus(ERROR)
        setTimeout(() => {
          setStakeStatus(IDLE)
        }, IDLE_DELAY)
      }
    }
  }

  const withdraw = async () => {
    if (abcPlatform) {
      setWithdrawStatus(PROCESSING)
      try {
        const q = await abcPlatform.withdraw(parseToBigNumber(project?.index).toNumber())
        const res = await q.wait()
        switch (res.status) {
          case 0:
            setWithdrawStatus(ERROR)
            setTimeout(() => {
              setWithdrawStatus(IDLE)
            }, IDLE_DELAY)
            break
          case 1:
            setWithdrawStatus(SUCCESS)
            setTimeout(() => {
              setWithdrawStatus(IDLE)
              window.location.reload()
            }, IDLE_DELAY)
            break
          default:
            setWithdrawStatus(ERROR)
            setTimeout(() => {
              setWithdrawStatus(IDLE)
            }, IDLE_DELAY)
            break
        }
      } catch (e) {
        setWithdrawStatus(ERROR)
        setTimeout(() => {
          setWithdrawStatus(IDLE)
        }, IDLE_DELAY)
      }
    }
  }

  useEffect(() => {
    fetchMyAllowance()
  }, [fetchMyAllowance])

  return (
    <Stack
      minW={isLargerThan1024 ? 'xl' : ''}
      p={'22px'}
      alignItems={'center'}
      bg={'white'}
      border={'1px solid'}
      borderColor={'secondary.300'}
      borderRadius={'12px'}
    >
      <HStack mb={'14px'}>
        <TokenIcon symbol={'XUSD'} />
        <Text fontWeight={'bold'}>{project?.stableSymbol}</Text>
      </HStack>
      <Divider />
      <Stack
        direction={isLargerThan1024 ? 'row' : 'column'}
        alignItems={'center'}
        justifyContent={'space-between'}
        p={isLargerThan1024 ? '22px' : '0'}
        w={'full'}
        spacing={isLargerThan1024 ? '44px' : '22px'}
        pt={isLargerThan1024 ? '' : '11px'}
      >
        <Stack
          alignItems={'start'}
          direction={isLargerThan1024 ? 'column' : 'row'}
          justifyContent={'space-between'}
          w={'full'}
        >
          <Text color={'secondary.500'} fontWeight={'semibold'} whiteSpace={'nowrap'}>
            Period
          </Text>
          <Text fontWeight={'bold'} whiteSpace={'nowrap'}>
            Flexible
          </Text>
        </Stack>
        <Stack
          alignItems={'start'}
          direction={isLargerThan1024 ? 'column' : 'row'}
          justifyContent={'space-between'}
          w={'full'}
        >
          <Text color={'secondary.500'} fontWeight={'semibold'}>
            APY
          </Text>
          <Text fontWeight={'bold'} whiteSpace={'nowrap'}>
            {formatNumber(project?.apy, 2)}%
          </Text>
        </Stack>
        <Stack
          alignItems={'start'}
          direction={isLargerThan1024 ? 'column' : 'row'}
          justifyContent={'space-between'}
          w={'full'}
        >
          <Text color={'secondary.500'} fontWeight={'semibold'} whiteSpace={'nowrap'}>
            Total quantity locked
          </Text>
          <Text fontWeight={'bold'} whiteSpace={'nowrap'}>
            {`${formatNumber(project?.locked, 2)} ${project?.stableSymbol}`}
          </Text>
        </Stack>
        {!isLargerThan1024 && (
          <>
            {isStake ? (
              <HStack w={'full'} spacing={0}>
                <InputGroup>
                  <Input
                    minH={'44px'}
                    pr={'70px'}
                    value={stakeAmount}
                    borderRightRadius={0}
                    onChange={(e) => setStakeAmount(e.target.value)}
                    type={'number'}
                  />
                  <InputRightElement
                    pr={'44px'}
                    h={'full'}
                    children={
                      <Button
                        p={0}
                        variant={'ghost'}
                        fontWeight={'medium'}
                        onClick={() => {
                          setStakeAmount(parseToBigNumber(project?.myHoldings).toFixed())
                        }}
                      >
                        MAX
                      </Button>
                    }
                  />
                </InputGroup>
                {allowance.gte(parseToBigNumber(stakeAmount)) ? (
                  <Button
                    borderLeftRadius={0}
                    px={'22px'}
                    minH={'44px'}
                    onClick={stake}
                    isLoading={stakeStatus === PROCESSING}
                    disabled={parseToBigNumber(stakeAmount).lte(0) || parseToBigNumber(stakeAmount).gt(project?.myHoldings)}
                  >
                    {stakeStatus === IDLE && 'Confirm'}
                    {stakeStatus === SUCCESS && 'Success'}
                    {stakeStatus === ERROR && 'Error'}
                  </Button>
                ) : (
                  <Button
                    borderLeftRadius={0}
                    px={'22px'}
                    minH={'44px'}
                    onClick={async () => {
                      await stableApprove(
                        ABC_PLATFORM_ADDRESS[chainId ?? 1],
                        '0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF'
                      )
                      await fetchMyAllowance()
                    }}
                    isLoading={stableApproveStatus === PROCESSING}
                  >
                    {stakeStatus === IDLE && 'Approve'}
                    {stakeStatus === SUCCESS && 'Success'}
                    {stakeStatus === ERROR && 'Error'}
                  </Button>
                )}
              </HStack>
            ) : (
              <Button w={'240px'} minH={'44px'} onClick={() => setIsStake(true)}>
                Stake
              </Button>
            )}
            <Text fontSize={'xs'} fontWeight={'medium'} color={parseToBigNumber(project?.myHoldings).lt(stakeAmount) ? 'red' : 'secondary.500'}>
              Balance: {formatNumber(project?.myHoldings, 2)} {project?.stableSymbol}
            </Text>
            <Divider />
          </>
        )}
        <Stack
          alignItems={'start'}
          direction={isLargerThan1024 ? 'column' : 'row'}
          justifyContent={'space-between'}
          w={'full'}
        >
          <Text color={'secondary.500'} fontWeight={'semibold'} whiteSpace={'nowrap'}>
            My stake
          </Text>
          <Text fontWeight={'bold'} whiteSpace={'nowrap'}>
            {formatNumber(project?.myStake, 2)} {project?.stableSymbol}
          </Text>
        </Stack>
        {!isLargerThan1024 && (
          <>
            <Button
              w={'240px'}
              minH={'44px'}
              onClick={() => {
                setIsStake(false)
                withdraw()
              }}
              isLoading={withdrawStatus === PROCESSING}
            >
              {withdrawStatus === IDLE && 'Withdraw'}
              {withdrawStatus === SUCCESS && 'Success'}
              {withdrawStatus === ERROR && 'Error'}
            </Button>
            <Text fontSize={'xs'} color={'secondary.500'} fontWeight={'medium'}>
              Withdrawable: {formatNumber(project?.myPandIBalance, 2)} {project?.stableSymbol}
            </Text>
          </>
        )}
      </Stack>
      {isLargerThan1024 && (
        <Stack pb={'22px'} pt={'80px'}>
          <HStack w={'full'} px={'22px'} spacing={'44px'}>
            {isStake ? (
              <HStack w={'360px'} spacing={0}>
                <InputGroup>
                  <Input
                    maxH={'36px'}
                    pr={'60px'}
                    value={stakeAmount}
                    borderRightRadius={0}
                    onChange={(e) => setStakeAmount(e.target.value)}
                    type={'number'}
                  />
                  <InputRightElement
                    pr={'22px'}
                    h={'36px'}
                    children={
                      <Button
                        p={0}
                        variant={'ghost'}
                        fontWeight={'medium'}
                        onClick={() => {
                          setStakeAmount(parseToBigNumber(project?.myHoldings).toFixed())
                        }}
                      >
                        MAX
                      </Button>
                    }
                  />
                </InputGroup>
                {allowance.gte(parseToBigNumber(stakeAmount)) ? (
                  <Button
                    borderLeftRadius={0}
                    px={'22px'}
                    minW={'100px'}
                    onClick={stake}
                    disabled={parseToBigNumber(stakeAmount).lt(0) || parseToBigNumber(stakeAmount).gt(project?.myHoldings)}
                    isLoading={stakeStatus === PROCESSING}
                  >
                    {stakeStatus === IDLE && 'Confirm'}
                    {stakeStatus === SUCCESS && 'Success'}
                    {stakeStatus === ERROR && 'Error'}
                  </Button>
                ) : (
                  <Button
                    borderLeftRadius={0}
                    px={'22px'}
                    minW={'100px'}
                    onClick={async () => {
                      await stableApprove(
                        ABC_PLATFORM_ADDRESS[chainId ?? 1],
                        '0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF'
                      )
                      await fetchMyAllowance()
                    }}
                    isLoading={stableApproveStatus === PROCESSING}
                  >
                    {stableApproveStatus === IDLE && 'Approve'}
                    {stableApproveStatus === SUCCESS && 'Success'}
                    {stableApproveStatus === ERROR && 'Error'}
                  </Button>
                )}
              </HStack>
            ) : (
              <Button w={'360px'} onClick={() => setIsStake(true)} disabled={parseToBigNumber(project?.myHoldings).eq(0)}>
                {parseToBigNumber(project?.myStake).gt(0) ? 'Add' : ''} Stake
              </Button>
            )}
            <Button
              w={'150px'}
              isLoading={withdrawStatus === PROCESSING}
              disabled={parseToBigNumber(project?.myStake).eq(0)}
              onClick={() => {
                setIsStake(false)
                withdraw()
              }}
            >
              {withdrawStatus === IDLE && 'Withdraw'}
              {withdrawStatus === SUCCESS && 'Success'}
              {withdrawStatus === ERROR && 'Error'}
            </Button>
          </HStack>
          <HStack w={'full'} px={'22px'}>
            <Text fontSize={'xs'} fontWeight={'medium'} color={parseToBigNumber(project?.myHoldings).lt(stakeAmount) ? 'red' : 'secondary.500'}>
              Balance: {formatNumber(project?.myHoldings, 2)} {project?.stableSymbol}
            </Text>
            <Spacer />
            <Text fontSize={'xs'} fontWeight={'medium'} color={'secondary.500'}>
              Withdrawable: {formatNumber(project?.myPandIBalance, 2)} {project?.stableSymbol}
            </Text>
          </HStack>
        </Stack>
      )}
    </Stack>
  )
}

export default Stake
