import { SupportedChainId } from './chains'

type AddressMap = { [chainId: number]: string }

export const NEST_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x04abEdA201850aC0124161F037Efd70c74ddC74C',
  [SupportedChainId.RINKEBY]: '0xE313F3f49B647fBEDDC5F2389Edb5c93CBf4EE25',
  [SupportedChainId.BSC]: '0x98f8669F6481EbB341B522fCD3663f79A3d1A6A7',
  [SupportedChainId.BSCTestnet]: '0x821edD79cc386E56FeC9DA5793b87a3A52373cdE',
  [SupportedChainId.KCC]: '0x98f8669F6481EbB341B522fCD3663f79A3d1A6A7',
  [SupportedChainId.GOERLI]: '0xE2975bf674617bbCE57D2c72dCfC926716D8AC1F',
}

export const PUSD_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0xCCEcC702Ec67309Bc3DDAF6a42E9e5a6b8Da58f0',
  [SupportedChainId.RINKEBY]: '0x5407cab67ad304FB8A4aC46D83b3Dd63A9dbA575',
  [SupportedChainId.BSC]: '0x9b2689525e07406D8A6fB1C40a1b86D2cd34Cbb2',
  [SupportedChainId.BSCTestnet]: '0x3DA5c9aafc6e6D6839E62e2fB65825869019F291',
  [SupportedChainId.KCC]: '0x0C4CD7cA70172Af5f4BfCb7b0ACBf6EdFEaFab31',
  [SupportedChainId.POLYGON]: '0xf26D86043a3133Cc042221Ea178cAED7Fe0eE362',
  [SupportedChainId.GOERLI]: '0x5cbb73B367FD69807381d06BC2041BEc86d8487d',
}

export const PETH_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.RINKEBY]: '0x0000000000000000000000000000000000000000',
  [SupportedChainId.BSC]: '0x556d8bF8bF7EaAF2626da679Aa684Bac347d30bB',
  [SupportedChainId.BSCTestnet]: '0xc39dC1385a44fBB895991580EA55FC10e7451cB3',
  [SupportedChainId.KCC]: '0x6cce8b9da777Ab10B11f4EA8510447431ED6ad1E',
  [SupportedChainId.POLYGON]: '0x1E0967e10B5Ef10342d4D71da69c30332666C899',
  [SupportedChainId.GOERLI]: '0x0000000000000000000000000000000000000000',
}

export const PBTC_ADDRESS: AddressMap = {
  [SupportedChainId.GOERLI]: '0x48e5c876074549cD4Bb7be0800154450b59b1eB6',
}

export const HBTC_ADDRESS: AddressMap = {
  [SupportedChainId.MAINNET]: '0x0316EB71485b0Ab14103307bf65a021042c6d380',
  [SupportedChainId.RINKEBY]: '0xaE73d363Cb4aC97734E07e48B01D0a1FF5D1190B',
  [SupportedChainId.BSC]: '0x46893c30fBDF3A5818507309c0BDca62eB3e1E6b',
  [SupportedChainId.BSCTestnet]: '0xaE73d363Cb4aC97734E07e48B01D0a1FF5D1190B',
  [SupportedChainId.KCC]: '0x32D4a9a94537a88118e878c56b93009Af234A6ce',
  [SupportedChainId.GOERLI]: '',
}

export const ABC_PLATFORM_ADDRESS: AddressMap = {
  [SupportedChainId.RINKEBY]: '0x74130b7f9CC53631184f2fb776571e0ce8384362',
  [SupportedChainId.GOERLI]: '0x6eb987Bd8ACE2a41438a3C747B96b0bB7c654B26',
}

export const NEST_BATCH_PLATFORM2_ADDRESS: AddressMap = {
  [SupportedChainId.RINKEBY]: '0xc08E6A853241B9a08225EECf93F3b279FA7A1bE7',
  [SupportedChainId.GOERLI]: '0x3948F9ec377110327dE3Fb8176C8Ed46296d76bA',
}
