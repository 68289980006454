import {FC, useEffect} from 'react'
import { HStack, Link, Stack, Td, Text, Tr, useMediaQuery } from '@chakra-ui/react'
import { formatNumber, parseToBigNumber } from '../../utils/bignumberUtil'
import { ExplorerDataType, getExplorerLink } from '../../utils/getExplorerLink'
import Divider from '../../components/Divider'
import { useNavigate } from 'react-router-dom'
import { ABCProject } from '../../hooks/useAbcProjects'
import useAbcProject from "../../hooks/useAbcProject";
import TokenIcon from "../../components/TokenIcon";
import useActiveWeb3React from "../../hooks/useActiveWeb3React";

export const ProjectItem: FC<ABCProject> = ({ ...project }) => {
  const { chainId } = useActiveWeb3React()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const navigate = useNavigate()
  // const { fetchAll } = useAbcProject(project)

  // useEffect(()=>{
  //   fetchAll()
  // }, [fetchAll])

  if (!isLargerThan1024) {
    return (
      <Stack
        bg={'white'}
        p={'22px'}
        borderRadius={'12px'}
        border={'1px solid'}
        borderColor={'secondary.300'}
        onClick={() => {
          navigate(`/p/${parseToBigNumber(project?.index).toFixed()}?from=p`)
        }}
        cursor={'pointer'}
      >
        <HStack>
          <Stack w={'full'}>
            <Text fontWeight={'semibold'} color={'secondary.500'}>
              Time of creation
            </Text>
            <Text fontWeight={'bold'} whiteSpace={'nowrap'}>
              {project?.time}
            </Text>
          </Stack>
          <Stack w={'full'}>
            <Text fontWeight={'semibold'} color={'secondary.500'}>
              Stablecoin
            </Text>
            <Link
              href={getExplorerLink(chainId ?? 1, project?.stablecoin, ExplorerDataType.TOKEN)}
              isExternal
              color={'#0082AA'}
            >
              <HStack>
                <TokenIcon symbol={'XUSD'} />
                <Text fontWeight={'bold'}>{`${project?.stableSymbol}`}</Text>
              </HStack>
            </Link>
          </Stack>
        </HStack>
        <Divider />
        <HStack>
          <Stack w={'full'}>
            <Text fontWeight={'semibold'} color={'secondary.500'}>
              Quantity minted
            </Text>
            <Text fontWeight={'bold'}>{`${formatNumber(project?.totalSupply, 2)}`}</Text>
          </Stack>
          <Stack w={'full'}>
            <Text fontWeight={'semibold'} color={'secondary.500'}>
              Quantity locked
            </Text>
            <Text fontWeight={'bold'}>{formatNumber(parseToBigNumber(project?.locked), 2)}</Text>
          </Stack>
        </HStack>
        <Divider />
        <HStack>
          <Stack w={'full'}>
            <Text fontWeight={'semibold'} color={'secondary.500'}>
              Quantity in circulation
            </Text>
            <Text fontWeight={'bold'}>{formatNumber(parseToBigNumber(project?.circulation), 2)}</Text>
          </Stack>
          <Stack w={'full'}>
            <Text fontWeight={'semibold'} color={'secondary.500'}>
              APY
            </Text>
            <Text fontWeight={'bold'}>{`${formatNumber(parseToBigNumber(project?.apy), 2)}%`}</Text>
          </Stack>
        </HStack>
        <Divider />
        <HStack>
          <Stack w={'full'}>
            <Text fontWeight={'semibold'} color={'secondary.500'}>
              Reserve assets
            </Text>
            <HStack>
              <TokenIcon symbol={project?.targetSymbol} />
              <Text fontWeight={'bold'} color={'#0082AA'}>{project?.targetSymbol}</Text>
            </HStack>
          </Stack>
          <Stack w={'full'}>
            <Text fontWeight={'semibold'} color={'secondary.500'}>
              AAR
            </Text>
            <Text fontWeight={'bold'}>{`${formatNumber(parseToBigNumber(project?.aar).shiftedBy(2), 2)} %`}</Text>
          </Stack>
        </HStack>
      </Stack>
    )
  }

  return (
    <Tr
      fontSize={'xs'}
      fontWeight={'semibold'}
      _hover={{ bg: '#F7F8FA' }}
      onClick={() => {
        navigate(`/p/${project?.index}?from=p`)
      }}
      cursor={'pointer'}
    >
      <Td px={'12px'}>
        <Link
          href={getExplorerLink(chainId ?? 1, project?.stablecoin, ExplorerDataType.TOKEN)}
          isExternal
          color={'#0082AA'}
        >
          <HStack>
            <TokenIcon symbol={'XUSD'} />
            <Text fontWeight={'bold'}>{`${project?.stableSymbol}`}</Text>
          </HStack>
        </Link>
      </Td>
      <Td px={'12px'}>{`${formatNumber(parseToBigNumber(project?.totalSupply), 2)}`}</Td>
      <Td px={'12px'}>{`${formatNumber(parseToBigNumber(project?.locked), 2)}`}</Td>
      <Td px={'12px'}>{`${formatNumber(parseToBigNumber(parseToBigNumber(project?.circulation)), 2)}`}</Td>
      <Td px={'12px'}>{`${formatNumber(parseToBigNumber(project?.apy), 2)}%`}</Td>
      <Td px={'12px'}>
        {formatNumber(parseToBigNumber(project?.reserve), 2)} {project?.targetSymbol}
      </Td>
      <Td px={'12px'}>{`${formatNumber(parseToBigNumber(project?.aar).shiftedBy(2), 2)} %`}</Td>
      <Td color={'secondary.500'} px={'12px'}>
        {project?.time}
      </Td>
    </Tr>
  )
}
