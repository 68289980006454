import {
  Button,
  HStack,
  Input,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Th,
  Thead,
  Tr,
  Text,
  Select,
  Spacer,
  useMediaQuery,
  Tooltip,
  VStack, useToast, Box,
} from '@chakra-ui/react'
import {useEffect, useMemo, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {ArrowBackIcon, ArrowForwardIcon, ChevronDownIcon, ChevronUpIcon} from '@chakra-ui/icons'
import {parseToBigNumber} from '../../utils/bignumberUtil'
import {ProjectItem} from './ProjectItem'
import useAbcProjects, {ABCProject} from '../../hooks/useAbcProjects'
import {atom, useRecoilState} from 'recoil'
import NoResult from "../../assets/svg/NoResults.svg"
import useActiveWeb3React from "../../hooks/useActiveWeb3React";

export const pageSizeAtom = atom({
  key: 'abc:pageSize',
  default: Number(localStorage.getItem('abc:pageSize') ?? 10),
})

const currentPageAtom = atom({
  key: 'abc:current:projects:page',
  default: 1,
})

const sortKeyAtom = atom({
  key: 'abc:current:sortkey',
  default: 'totalSupply',
})

const sortAtom = atom({
  key: 'abc:current:sort',
  default: false,
})

const Project = () => {
  const [searchText, setSearchText] = useState('')
  const navigate = useNavigate()
  const [pageSize, setPageSize] = useRecoilState(pageSizeAtom)
  const [currentPage, setCurrentPage] = useRecoilState(currentPageAtom)
  const [inputPage, setInputPage] = useState('')
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const {projects, fetchAllProjects, fetchProjectsCount} = useAbcProjects()
  const [sortKey, setSortKey] = useRecoilState(sortKeyAtom)
  const [sort, setSort] = useRecoilState(sortAtom)
  const {chainId, account} = useActiveWeb3React()
  const toast = useToast()

  const count = useMemo(() =>
      projects
        .filter((item) => item.chainId === (chainId ?? 1))
        .length
    ,
    [projects, chainId])

  const searchCount = useMemo(() =>
      projects
        .filter((item) => item.chainId === (chainId ?? 1))
        .filter((item) => (searchText === '' ? true : (item.stablecoin.includes(searchText) || item.stableSymbol.toLowerCase().includes(searchText.toLowerCase()))))
        .length
    , [projects, chainId, searchText])

  useEffect(() => {
    fetchProjectsCount()
  }, [fetchProjectsCount])

  useEffect(() => {
    fetchAllProjects()
  }, [fetchAllProjects])

  const compare = (key: string, sort: boolean) => {
    return function (m: ABCProject, n: ABCProject) {
      // @ts-ignore
      const a = m[key]
      // @ts-ignore
      const b = n[key]
      if (sort) {
        if (isNaN(a - b)) {
          return a.localeCompare(b)
        }
        return a - b
      } else {
        if (isNaN(b - a)) {
          return b.localeCompare(a)
        }
        return b - a
      }
    }
  }

  const currentPageProjects = useMemo(() => {
    return projects
      .filter((item) => item.chainId === (chainId ?? 1))
      .sort(compare(sortKey, sort))
      .filter((item) => (searchText === '' ? true : (item.stablecoin.includes(searchText) || item.stableSymbol.toLowerCase().includes(searchText.toLowerCase()))))
      .filter((item, index) => Math.ceil((index + 1) / pageSize) === currentPage)
  }, [currentPage, pageSize, projects, searchText, sortKey, sort])

  const heads = [
    {id: 2, label: 'Stablecoin', tips: 'Stablecoin', key: 'targetSymbol'},
    {id: 3, label: 'Quantity minted', tips: 'Quantity minted', key: 'totalSupply'},
    {id: 4, label: 'Quantity locked', tips: 'Quantity locked', key: 'locked'},
    {id: 5, label: 'Quantity in circulation', tips: 'Quantity in circulation', key: 'circulation'},
    {id: 6, label: 'APY', tips: 'APY', key: 'apy'},
    {id: 7, label: 'Reserve assets', tips: 'Reserve assets', key: 'reserve'},
    {id: 8, label: 'AAR', tips: 'Asset adequacy ratio', key: 'aar'},
    {id: 1, label: 'Time of creation', tips: 'Time of creation', key: 'time'},
  ]

  const maxPages = useMemo(
    () => {
      if (searchText === '') {
        return (Math.ceil(count / pageSize) >= 1 ? Math.ceil(count / pageSize) : 1)
      } else {
        return (Math.ceil(searchCount / pageSize) >= 1 ? Math.ceil(searchCount / pageSize) : 1)
      }
    },
    [count, pageSize, searchText, searchCount]
  )

  useEffect(() => {
    if (inputPage && Number(inputPage) > 0 && Number(inputPage) <= maxPages) {
      setCurrentPage(Number(inputPage))
    }
  }, [inputPage, maxPages])

  return (
    <Stack h={'full'} px={'22px'} py={isLargerThan1024 ? '22px' : 0} spacing={'22px'}>
      <Stack
        direction={isLargerThan1024 ? 'row' : 'column'}
        w={'full'}
        justifyContent={'center'}
        spacing={isLargerThan1024 ? 5 : '30px'}
        bg={isLargerThan1024 ? '' : 'white'}
        px={isLargerThan1024 ? '' : '20px'}
        py={isLargerThan1024 ? '' : '40px'}
        borderRadius={'12px'}
        alignItems={'center'}
        border={isLargerThan1024 ? '' : '1px solid'}
        borderColor={isLargerThan1024 ? '' : 'secondary.300'}
      >
        <Input
          variant="filled"
          width={isLargerThan1024 ? 'xl' : 'full'}
          minH={isLargerThan1024 ? '' : '44px'}
          placeholder="Search by stablecoin symbol / address"
          fontSize={searchText === '' ? (isLargerThan1024 ? '15px' : 'xs') : '17px'}
          onChange={(e) => {
            setSearchText(e.target.value)
          }}
        />
        <Button
          variant={'outline'}
          bg={'null'}
          onClick={() => {
            if (account) {
              navigate('/p/create')
            } else {
              toast({
                position: 'top',
                render: () => (
                  <Box
                    color="white"
                    p={3}
                    px={6}
                    bg="primary.500"
                    textAlign={'center'}
                    fontWeight={'bold'}
                    borderRadius={'full'}
                  >
                    Connect wallet first!
                  </Box>
                ),
              })
            }

          }}
          minH={isLargerThan1024 ? '' : '44px'}
        >
          Create Project
        </Button>
      </Stack>

      {count > 0 ? (
        <>
          {searchCount > 0 ? (
            <>
              {!isLargerThan1024 && (
                <HStack
                  bg={'white'}
                  p={'22px'}
                  borderRadius={'12px'}
                  justifyContent={'space-between'}
                  border={'1px solid'}
                  borderColor={'secondary.300'}
                >
                  <Text fontWeight={'semibold'} whiteSpace={'nowrap'}>
                    Sort by
                  </Text>
                  <Select
                    w={'240px'}
                    variant={'unstyled'}
                    fontWeight={'semibold'}
                    cursor={'pointer'}
                    textAlign={'right'}
                    pr={'20px'}
                    onChange={(e) => {
                      setSortKey(e.target.value)
                    }}
                  >
                    {heads.map((item) => (
                      <option key={item.id} value={item.key}>
                        {item.label}
                      </option>
                    ))}
                  </Select>
                </HStack>
              )}
              {isLargerThan1024 ? (
                <TableContainer
                  bg={'white'}
                  borderRadius={12}
                  h={'full'}
                  border={'1px solid'}
                  borderColor={'secondary.300'}
                  px={4}
                >
                  <Table>
                    <Thead>
                      <Tr>
                        {heads.map((item) => (
                          <Th
                            px={'12px'}
                            textTransform={'none'}
                            color={'secondary.500'}
                            fontSize={'sm'}
                            fontWeight={'semibold'}
                            fontFamily={'Montserrat'}
                            key={item.id}
                            cursor={'pointer'}
                            userSelect={'none'}
                          >
                            <Tooltip
                              label={item.tips}
                              bg={'white'}
                              borderRadius={'full'}
                              color={'link.500'}
                              whiteSpace={'nowrap'}
                            >
                              <HStack
                                spacing={'6px'}
                                onClick={() => {
                                  setSort(sortKey === item.key ? !sort : false)
                                  setSortKey(item.key)
                                }}
                              >
                                <Text
                                  color={sortKey === item.key ? '#00B7EE' : ''}
                                >
                                  {item.label}
                                </Text>
                                <VStack spacing={'0'}>
                                  <ChevronUpIcon
                                    color={sortKey === item.key && sort ? '#00B7EE' : ''}
                                  />
                                  <ChevronDownIcon
                                    color={sortKey === item.key && !sort ? '#00B7EE' : ''}
                                  />
                                </VStack>
                              </HStack>
                            </Tooltip>
                          </Th>
                        ))}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {currentPageProjects.map((item) => (
                        <ProjectItem {...item} key={parseToBigNumber(item.index).toNumber()}/>
                      ))}
                    </Tbody>
                  </Table>
                  <HStack py={2} px={'12px'} fontSize={'sm'} fontWeight={'semibold'} color={'secondary.500'}>
                    <Text>Total {count} Projects</Text>
                    <Select
                      h={7}
                      w={48}
                      fontSize={'xs'}
                      border={'1px'}
                      value={pageSize}
                      onChange={(e) => {
                        setPageSize(Number(e.target.value))
                        localStorage.setItem('abc:pageSize', e.target.value)
                      }}
                    >
                      {[10, 25, 50, 100].map((item) => (
                        <option key={item} value={item}>
                          {item} projects/page
                        </option>
                      ))}
                    </Select>
                    {maxPages > 1 && (
                      <>
                        <Spacer/>
                        <Button
                          variant={'ghost'}
                          disabled={currentPage === 1}
                          onClick={() => {
                            if (currentPage > 1) {
                              setCurrentPage(currentPage - 1)
                            }
                          }}
                        >
                          <ArrowBackIcon/>
                        </Button>
                        {[1, 2, 3, 4, 5].map((item) => (
                          <Button
                            key={item}
                            variant={currentPage === item ? 'solid' : 'ghost'}
                            color={currentPage === item ? 'black' : 'secondary.500'}
                            size={'xs'}
                            onClick={() => setCurrentPage(item)}
                            hidden={maxPages < item}
                          >
                            {item}
                          </Button>
                        ))}
                        <Button
                          variant={'ghost'}
                          m={0}
                          ml={0}
                          disabled={currentPage === maxPages}
                          onClick={() => {
                            if (currentPage < maxPages) {
                              setCurrentPage(currentPage + 1)
                            }
                          }}
                        >
                          <ArrowForwardIcon/>
                        </Button>
                        <Text color={'black'}>go to</Text>
                        <Input
                          maxH={7}
                          px={0}
                          textAlign={'center'}
                          maxW={'36px'}
                          value={inputPage}
                          type={'number'}
                          onChange={(e) => setInputPage(e.target.value)}
                        />
                      </>
                    )}
                  </HStack>
                </TableContainer>
              ) : (
                <Stack spacing={'22px'}>
                  {currentPageProjects.map((item) => (
                    <ProjectItem {...item} key={parseToBigNumber(item.index).toNumber()}/>
                  ))}
                  <Spacer/>
                  {maxPages > 1 && (
                    <HStack w={'full'} justifyContent={'space-between'}>
                      <Button
                        variant={'ghost'}
                        disabled={currentPage === 1}
                        onClick={() => {
                          if (currentPage > 1) {
                            setCurrentPage(currentPage - 1)
                          }
                        }}
                      >
                        <ArrowBackIcon fontSize={'lg'}/>
                      </Button>
                      {[1, 2, 3, 4, 5].map((item) => (
                        <Button
                          key={item}
                          variant={currentPage === item ? 'solid' : 'ghost'}
                          color={currentPage === item ? 'black' : 'secondary.500'}
                          onClick={() => setCurrentPage(item)}
                          size={'sm'}
                          hidden={item > maxPages}
                        >
                          {item}
                        </Button>
                      ))}
                      <Button
                        variant={'ghost'}
                        m={0}
                        ml={0}
                        disabled={currentPage === maxPages}
                        onClick={() => {
                          if (currentPage < maxPages) {
                            setCurrentPage(currentPage + 1)
                          }
                        }}
                      >
                        <ArrowForwardIcon fontSize={'lg'}/>
                      </Button>
                    </HStack>
                  )}
                </Stack>
              )}
            </>
          ) : (
            <Stack
              w={'full'}
              h={isLargerThan1024 ? '640px' : '400px'}
              alignItems={"center"}
              justify={"center"}
              bg={'white'}
              borderRadius={'12px'}
              spacing={'40px'}
            >
              <img src={NoResult} width={'125px'}/>
              <Text fontWeight={'semibold'}>No results</Text>
            </Stack>
          )}
        </>
      ) : (
        <Stack
          w={'full'}
          h={isLargerThan1024 ? '640px' : '400px'}
          alignItems={"center"}
          justify={"center"}
          bg={'white'}
          borderRadius={'12px'}
          spacing={'40px'}
        >
          <img src={NoResult} width={'125px'}/>
          <HStack>
            <Text fontWeight={'semibold'}>No project created yet</Text>
            <Text color={'#00B7EE'} fontWeight={'semibold'} cursor={"pointer"} onClick={() => {
              if (account) {
                navigate('/p/create')
              } else {
                toast({
                  position: 'top',
                  render: () => (
                    <Box
                      color="white"
                      p={3}
                      px={6}
                      bg="primary.500"
                      textAlign={'center'}
                      fontWeight={'bold'}
                      borderRadius={'full'}
                    >
                      Connect wallet first!
                    </Box>
                  ),
                })
              }
            }}>go to create</Text>
          </HStack>
        </Stack>
      )}
    </Stack>
  )
}

export default Project
