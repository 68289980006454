import {
  Box,
  Button,
  FormControl,
  Input,
  InputGroup,
  InputRightElement,
  NumberInput,
  NumberInputField,
  Stack,
  Text,
  useMediaQuery,
} from '@chakra-ui/react'
import Divider from '../Divider'
import {FC, useRef, useState} from 'react'
import {formatWithUnit, parseToNumber} from '../../utils/unit'
import {BiChevronDownCircle} from 'react-icons/all'
import useWindowSize from '../../hooks/useWindowSize'

type OptionInput = {
  title: string
  defaultValue: string
  onChange: (value: string) => void
  datalist: item[]
  onCheck: (value: string) => boolean
  unit?: string
  isNumber?: boolean
  max?: number
  min?: number
  onlySelect?: boolean
  placeholder?: string
  readonly?: boolean
  label?: string
}

type item = {
  title: string
  data: string
}

const InputWithSelect: FC<OptionInput> = ({...props}) => {
  const [showOption, setShowOption] = useState(false)
  const [value, setValue] = useState(props.defaultValue)
  const inputRef = useRef(null)
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const {width} = useWindowSize()

  return (
    <Box pb={showOption ? (isLargerThan1024 ? '40px' : '44px') : '0'} w={isLargerThan1024 ? '600px' : 'full'}>
      <Text
        fontWeight={'600'}
        mb={isLargerThan1024 ? '16px' : '10px'}
        fontSize={isLargerThan1024 ? 'md' : 'xs'}
        ml={'16px'}
        color={'secondary.500'}
      >
        {props.title}:
      </Text>
      <FormControl
        bg={'white'}
        width={isLargerThan1024 ? 600 : width - 66}
        borderRadius={showOption ? '10px' : '0'}
        border={showOption ? '1px' : '0'}
        borderColor={'primary.500'}
        pos={showOption ? 'absolute' : 'static'}
        zIndex={showOption ? 10 : 0}
      >
        {props.isNumber ? (
          <NumberInput
            variant={showOption ? 'unstyled' : 'filled'}
            isInvalid={props.onCheck(value)}
            errorBorderColor={'primary.500'}
            onChange={(valueString) => {
              setValue(parseToNumber(valueString, props.unit))
              props.onChange(parseToNumber(valueString, props.unit))
            }}
            isReadOnly={props.readonly}
            value={formatWithUnit(value, props.unit)}
            max={props.max}
            min={props.min}
            onFocus={(e) => {
              e.target.setSelectionRange(0, value.length)
              setShowOption(true)
            }}
            onBlur={() => {
              setTimeout(() => setShowOption(false), 200)
            }}
          >
            <NumberInputField ref={inputRef} minH={isLargerThan1024 ? '40px' : '44px'} readOnly={props.onlySelect}/>
            <InputRightElement
              onClick={() => {
                if (!showOption) {
                  // @ts-ignore
                  inputRef.current.focus()
                }
              }}
              cursor={"pointer"}
              h={isLargerThan1024 ? '40px' : '44px'}
              children={
                <Stack pr={'12px'}>
                  <BiChevronDownCircle size={isLargerThan1024 ? '22px' : '16px'} color={'#878787'}/>
                </Stack>
              }
            />
          </NumberInput>
        ) : (
          <InputGroup>
            <Input
              id={'amount'}
              ref={inputRef}
              minH={isLargerThan1024 ? '40px' : '44px'}
              variant={showOption ? 'unstyled' : 'filled'}
              errorBorderColor={'primary.500'}
              placeholder={props?.placeholder || ''}
              fontSize={value === '' ? '15px' : '17px'}
              readOnly={props.onlySelect}
              isReadOnly={props.readonly}
              isInvalid={props.onCheck(value)}
              onChange={(event) => {
                setValue(parseToNumber(event.target.value, props.unit))
                props.onChange(parseToNumber(event.target.value, props.unit))
              }}
              value={formatWithUnit(value, props.unit)}
              onFocus={(e) => {
                e.target.setSelectionRange(0, value.length)
                setShowOption(true)
              }}
              onBlur={() => {
                setTimeout(() => setShowOption(false), 200)
              }}
            />
            {props.label && (
              <InputRightElement
                w={'100px'}
                justifyContent={'end'}
                onClick={() => {
                  if (!showOption) {
                    // @ts-ignore
                    inputRef.current.focus()
                  }
                }}
                h={isLargerThan1024 ? '40px' : '44px'}
                children={
                  <Stack pr={'16px'}>
                    <Text fontWeight={'semibold'} color={props.onCheck(value) ? 'red' : 'primary.500'}
                          textAlign={'right'}>
                      {props.label}
                    </Text>
                  </Stack>
                }
              />
            )}
            {props.onlySelect && (
              <InputRightElement
                onClick={() =>
                  // @ts-ignore
                  inputRef.current.focus()
                }
                cursor={'pointer'}
                h={isLargerThan1024 ? '40px' : '44px'}
                children={
                  <Stack pr={'12px'}>
                    <BiChevronDownCircle size={isLargerThan1024 ? '22px' : '16px'} color={'#878787'}/>
                  </Stack>
                }
              />
            )}
          </InputGroup>
        )}

        {props.datalist.length > 0 && !props.readonly && (
          <Stack hidden={!showOption} pb={1} top={'72px'} spacing={0}>
            <Divider/>
            {props.datalist.map((item, index) => (
              <Button
                variant={'ghost'}
                justifyContent={'flex-start'}
                fontWeight={'600'}
                fontSize={'17px'}
                borderRadius={0}
                key={index}
                onClick={() => {
                  setValue(parseToNumber(item.data, props.unit))
                  props.onChange(parseToNumber(item.data, props.unit))
                  setShowOption(false)
                }}
                _hover={{bg: 'secondary.400'}}
              >
                {item.title}
              </Button>
            ))}
          </Stack>
        )}
      </FormControl>
    </Box>
  )
}

export default InputWithSelect
