import { useCallback, useEffect, useState } from 'react'
import { useNESTBatchPlatform2 } from './useContract'
import {NEST_BATCH_PLATFORM2_ADDRESS, PUSD_ADDRESS} from '../constants/addresses'
import {CHANNEL_OPEN_LOGS_FILTER} from "../constants/logs";
import {useInterval} from "@chakra-ui/react";
import useActiveWeb3React from "./useActiveWeb3React";

export type ChannelInfo = {
  title: string
  channelId: number
  pairIndex: number
  priceToken: string
  token: string
}

const useNestPlatform = () => {
  const [channelList, setChannelList] = useState<ChannelInfo[]>([])
  const [count, setCount] = useState(0)
  const { chainId } = useActiveWeb3React()
  const nestPlatform = useNESTBatchPlatform2(NEST_BATCH_PLATFORM2_ADDRESS[chainId ?? 1], true)

  const fetchCount = useCallback(async () => {
    try {
      const request = await fetch(
        CHANNEL_OPEN_LOGS_FILTER[chainId ?? 1].hostname +
        '/api?module=logs&action=getLogs' +
        '&fromBlock=' +
        CHANNEL_OPEN_LOGS_FILTER[chainId ?? 1].fromBlock +
        '&toBlock=' +
        CHANNEL_OPEN_LOGS_FILTER[chainId ?? 1].toBlock +
        '&address=' +
        CHANNEL_OPEN_LOGS_FILTER[chainId ?? 1].address +
        '&topic0=' +
        CHANNEL_OPEN_LOGS_FILTER[chainId ?? 1].topics[0] +
        '&apikey=' +
        CHANNEL_OPEN_LOGS_FILTER[chainId ?? 1].apikey
      )
      const data = await request.json()
      if (data.status === '1') {
        const logs = data.result
        setCount(logs.length)
      }
    } catch (e) {
      console.log(e)
    }
  }, [chainId])

  const fetchChannelInfo = useCallback(async () => {
    let list: ChannelInfo[] = []
    for (let channelId = 0; channelId < count; channelId++) {
      const res = await nestPlatform?.getChannelInfo(channelId)
      if (res) {
        res.pairs.forEach((e, index) => {
          if (res.token0 === PUSD_ADDRESS[chainId ?? 1]) {
            list.push({
              title: `ChannelId: ${channelId}, PairIndex: ${index}`,
              channelId: channelId,
              pairIndex: index,
              token: e[0],
              priceToken: res.token0,
            })
          }
        })
      }
    }
    setChannelList(list)
  }, [chainId, count, nestPlatform])

  useEffect(()=> {
    fetchCount()
  }, [fetchCount])

  useEffect(() => {
    fetchChannelInfo()
  }, [fetchChannelInfo, chainId])

  useInterval(fetchChannelInfo, 10000)

  return {
    count,
    channelList,
  }
}

export default useNestPlatform
